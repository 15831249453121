import React, { useState } from "react";
import { useEffect } from "react";
import Logo from "../images/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import PublishIcon from "@mui/icons-material/Publish";
import ExploreIcon from "@mui/icons-material/Explore";
import {
  deactivateIndividual,
  fetchIndividualProfile,
  individualUserSelector,
  logOut,
} from "../api/individualUser";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, ListItemIcon, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Link, NavLink } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Box, Modal } from "@mui/material";
import { Button, ModalDialog } from "@mui/joy";
import DvrIcon from "@mui/icons-material/Dvr";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1000px",
  height: "500px",
  bgcolor: "background.paper",
  border: "px solid #000",
  boxShadow: 2,
  p: 2,
};

export default function Navbar(props) {
  const Text = `Coming Soon`;


  const { current_individual_user, individualtoken } = useSelector(
    individualUserSelector
  );

  const { token, user } = useSelector(individualUserSelector);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [openModel, setOpenModel] = useState(false);

  useEffect(() => {
    dispatch(fetchIndividualProfile(individualtoken));
  }, []);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State for mobile menu

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  const openModelFunction = () => {
    setAnchorEl(null);
    setOpenModel(true);
  };

  const handelModelClose = () => {
    setOpenModel(false);
  };

  const handleSubMenuClick = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const deactiveAccount = () => {
    dispatch(deactivateIndividual(user?._id, individualtoken));
    setOpenModel(false);
  };
  // Function to toggle mobile menu
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="sticky top-0 z-50">
      <section style={{ fontFamily: "Lato" }}>
        <nav className="relative flex items-center justify-between p-2 bg-gray-100">
          <div className="w-full xl:w-auto px-2 xl:mr-12">
            <div className="flex items-center justify-between">
              <a className="inline-flex items-center" href="#">
                <img src={Logo} alt="" />
              </a>
              <div className="xl:hidden">
                <Link
                  to={"/notifications"}
                  class="inline-block mb-2 mr-8 text-gray-600 "
                >
                  <span>
                    <NotificationsNoneIcon />
                    {user?.notifications.filter(
                      (item) => item?.user?.profile_activate_status
                    )?.length ? (
                      <sup className="bg-red-600 text-white p-1 rounded-lg">
                        {
                          user?.notifications.filter(
                            (item) => item?.user?.profile_activate_status
                          )?.length
                        }
                      </sup>
                    ) : null}
                  </span>
                </Link>
                <button
                  className="navbar-burger text-gray-400 hover:text-gray-300 focus:outline-none"
                  onClick={toggleMobileMenu}
                >
                  <svg
                    width="20"
                    height="12"
                    viewBox="0 0 20 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Mobile menu</title>
                    <path
                      d="M1 2H19C19.2652 2 19.5196 1.89464 19.7071 1.70711C19.8946 1.51957 20 1.26522 20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM19 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H19C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10ZM19 5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H19C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <div className="xl:hidden">
       
            {mobileMenuOpen && (
              <div
                className="xl:hidden bg-gray-100 absolute right-0 w-full"
                style={{ top: "100%" }}
              >
                <ul className="flex flex-col items-start w-50">
                  <li>  
                    <NavLink
                    onClick={toggleMobileMenu}
                      to="/home"
                      className="lg:text-black lg:hover:text-gray-700 px-3 py-2 flex items-center text-md font-medium leading-5 text-black focus:outline-none transition duration-150 ease-in-out"
                    >
                      <HomeIcon className="mr-1" /> Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                    onClick={toggleMobileMenu}
                      to="/connection"
                      className="lg:text-black lg:hover:text-gray-700 px-3 py-2 flex items-center text-md font-medium leading-5 text-black focus:outline-none transition duration-150 ease-in-out"
                    >
                      <PeopleAltIcon className="mr-1" /> Connect
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleMobileMenu}
                      to="/jobs"
                      className="lg:text-black lg:hover:text-gray-700 px-3 py-2 flex items-center text-md font-medium leading-5 text-black focus:outline-none transition duration-150 ease-in-out"
                    >
                      <WorkIcon className="mr-1" /> Jobs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/publish"
                      onClick={toggleMobileMenu}
                      className="lg:text-black lg:hover:text-gray-700 px-3 py-2 flex items-center text-md font-medium leading-5 text-black focus:outline-none transition duration-150 ease-in-out"
                    >
                      <PublishIcon className="mr-1" /> Publish
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/explore"
                      onClick={toggleMobileMenu}
                      className="lg:text-black lg:hover:text-gray-700 px-3 py-2 flex items-center text-md font-medium leading-5 text-black focus:outline-none transition duration-150 ease-in-out"
                    >
                      <ExploreIcon className="mr-1" /> Explore
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/topics"
                      onClick={toggleMobileMenu}
                      className="lg:text-black lg:hover:text-gray-700 px-3 py-2 flex items-center text-md font-medium leading-5 text-black focus:outline-none transition duration-150 ease-in-out"
                    >
                      <DvrIcon className="mr-1" /> Topics
                    </NavLink>
                  </li>

                  <li>
                    <div class="flex flex-wrap items-center -mb-1">
                      {/* <Link
                      to={"/notifications"}
                      class="inline-block mb-2 mr-8 text-gray-600 "
                    >
                    <span>
                      <NotificationsNoneIcon />
                      {user?.notifications.filter(
                        (item) => item?.user?.profile_activate_status
                      )?.length ? (
                        <sup className="bg-red-600 text-white p-1 rounded-lg">
                          {
                            user?.notifications.filter(
                              (item) => item?.user?.profile_activate_status
                            )?.length
                          }
                        </sup>
                      ) : null}
                    </span>
                  </Link> */}
                      <div>
                        <IconButton
                          color="inherit"
                          id="basic-button"
                          aria-controls={open ? "main-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        >
                          <Avatar
                            alt=""
                            src={user?.profile_completion?.profile_pic}
                          />
                          <span class="ml-3 text-gray-400 group-hover:text-gray-300">
                            <svg
                              width="10"
                              height="6"
                              viewbox="0 0 10 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 1L5 5L9 1"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </span>
                        </IconButton>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>

          {/* Desktop Menu */}
          <div class="hidden xl:block w-full md:w-auto px-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <ul class="flex items-center">
              <NavLink to="/home">
                <li class="mr-6">
                  <a
                    class="inline-flex items-center text-sm font-semibold text-gray-600 hover:text-gray-900"
                    href="#"
                  >
                    <HomeIcon />
                    <span class="ml-2">Home</span>
                  </a>
                </li>
              </NavLink>
              <NavLink to="/connection">
                <li class="mr-6">
                  <a
                    class="inline-flex items-center text-sm font-semibold text-gray-600 hover:text-gray-900"
                    href="#"
                  >
                    <PeopleAltIcon />
                    <span class="ml-2">Connect</span>
                  </a>
                </li>
              </NavLink>
              {/* <Tooltip title={Text}>  */}
              <NavLink to="/jobs">
                <li class="mr-6">
                  <a
                    class="inline-flex items-center text-sm font-semibold text-gray-600 hover:text-gray-900"
                    href="#"
                  >
                    <WorkIcon />
                    <span class="ml-2">Jobs</span>
                  </a>
                </li>
              </NavLink>
              {/* </Tooltip> */}
              {/* <Tooltip title={Text}> */}
              <NavLink to="/publish">
                <li class="mr-6">
                  <a
                    class="inline-flex items-center text-sm font-semibold text-gray-600 hover:text-gray-900"
                    href="#"
                  >
                    <PublishIcon />
                    <span class="ml-2">Publish</span>
                  </a>
                </li>
              </NavLink>
              {/* </Tooltip> */}
              {/* <Tooltip title={Text}> */}
              <NavLink to="/explore">
                <li class="mr-6">
                  <a
                    class="inline-flex items-center text-sm font-semibold text-gray-600 hover:text-gray-900"
                    href="#"
                  >
                    <ExploreIcon />
                    <span class="ml-2">Explore</span>
                  </a>
                </li>
              </NavLink>
              {/* </Tooltip> */}
              {/* <Tooltip title={Text}> */}
              <NavLink to="/topics">
                <li class="mr-6">
                  <a
                    class="inline-flex items-center text-sm font-semibold text-gray-600 hover:text-gray-900"
                    href="#"
                  >
                    <DvrIcon />
                    <span class="ml-2">Topics</span>
                  </a>
                </li>
              </NavLink>
              {/* </Tooltip> */}
            </ul>
          </div>

          <div class="hidden xl:block w-full md:w-auto px-2">
            <div class="md:flex items-center">
              <div class="w-full md:w-auto mb-6 md:mb-0 md:mr-4">
                <div class="flex flex-wrap items-center -mb-2">
                  <Link
                    to={"/notifications"}
                    class="inline-block mb-2 mr-8 text-gray-600 "
                  >
                    <span>
                      <NotificationsNoneIcon />
                      {user?.notifications.filter(
                        (item) => item?.user?.profile_activate_status
                      )?.length ? (
                        <sup className="bg-red-600 text-white p-1 rounded-lg">
                          {
                            user?.notifications.filter(
                              (item) => item?.user?.profile_activate_status
                            )?.length
                          }
                        </sup>
                      ) : null}
                    </span>
                  </Link>
                  <div className="z-30">
                    <IconButton
                      color="inherit"
                      id="basic-button"
                      aria-controls={open ? "main-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <Avatar
                        alt=""
                        src={user?.profile_completion?.profile_pic}
                      />
                      <span class="ml-3 text-gray-400 group-hover:text-gray-300">
                        <svg
                          width="10"
                          height="6"
                          viewbox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L5 5L9 1"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </span>
                    </IconButton>
                    <Menu
                      id="main-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          dispatch(logOut());
                        }}
                      >
                        <ListItemIcon className="px-4">
                          <Logout fontSize="medium" />
                          Logout
                        </ListItemIcon>
                      </MenuItem>
                      {/* <MenuItem>
                        <div
                          id="basic-button"
                          aria-controls={
                            Boolean(subMenuAnchorEl) ? "basic-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            Boolean(subMenuAnchorEl) ? "true" : undefined
                          }
                          onClick={handleSubMenuClick}
                        >
                          Account settings
                        </div>
                        <Menu
                          id="submenu"
                          anchorEl={subMenuAnchorEl}
                          open={Boolean(subMenuAnchorEl)}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              setOpenModel(true);
                              handleClose();
                            }}
                            style={{ color: "#DC2626", fontSize: "12px" }}
                          >
                            <div>Deactivate Account</div>
                          </MenuItem>
                        </Menu>
                      </MenuItem> */}
                    </Menu>
                  </div>
                </div>
              </div>
              <>
                <Modal open={openModel}>
                  <Box style={style}>
                    <ModalDialog
                      aria-labelledby="nested-modal-title"
                      aria-describedby="nested-modal-description"
                      sx={(theme) => ({
                        [theme.breakpoints.only("xs")]: {
                          top: "unset",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          borderRadius: 0,
                          transform: "none",
                          maxWidth: "unset",
                        },
                      })}
                    >
                      <div className="text-center w-[500px]">
                        <h1 className="text-red-600 mt-2">
                          Are you sure, you want to Deactivate the account?
                        </h1>
                      </div>
                      <div className="flex justify-center  mt-4">
                        <Button
                          variant="outlined"
                          color="neutral"
                          onClick={handelModelClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="solid"
                          color="contained"
                          style={{
                            backgroundColor: "#C42A09",
                            color: "white",
                            //   width: "25%",
                            marginLeft: "10px",
                          }}
                          onClick={deactiveAccount}
                        >
                          Deactivate Account
                        </Button>
                      </div>
                    </ModalDialog>
                  </Box>
                </Modal>
              </>
            </div>
          </div>
        </nav>
      </section>
      <Modal
        open={openModel}
        onClose={handelModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalDialog
            setOpenModel={setOpenModel}
            jobPost={true}
            buttonText={"Create Job"}
            title={"Post a Job"}
            Text={Text}
          />
        </Box>
      </Modal>
    </div>
  );
}
