import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import { individualUserSelector } from "../../api/individualUser";
import { NavLink, Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { nanoid } from "@reduxjs/toolkit";
import storage from "../../shared/firebaseStorage";
import { createPublish } from "../../api/publish";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WorkIcon from "@mui/icons-material/Work";
import { Keyareas } from "../../shared/constants"; // Assuming this is the correct import
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { PiCertificateDuotone } from "react-icons/pi";
import { IoDocument } from "react-icons/io5";
import { MdAssignmentTurnedIn, MdPublish } from "react-icons/md";
import { GiArchiveResearch, GiSaveArrow } from "react-icons/gi";
import { IoMdDownload } from "react-icons/io";


const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
}));

function PublishCertificate() {
  const { user } = useSelector(individualUserSelector);
  const [document, setDocument] = useState("");
  const [pdfPercent, setPdfPercent] = useState(0);
  const [uploadSuccessMsg, setUploadSuccessMsg] = useState("");
  const [selectedKeyArea, setSelectedKeyArea] = useState([]);
  const [skills, setSkills] = useState([]);

  const [publish, setPublish] = useState({
    description: "",
    publish_status: "Active",
  });

  useEffect(() => {
    const keyInterest = selectedKeyArea.map((item) => item.title);
    setSkills(keyInterest);
  }, [selectedKeyArea]);



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { pathname } = useLocation();

  const handleKeyAreaSelection = (value) => {
    if (value.length <= 4) {
      setSelectedKeyArea([...value]);
    } else {
      toast.warning("You can only select up to 4 domains", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // Handle file selection and upload
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.type !== "application/pdf") {
        toast.warning("You can only upload PDF files!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      const isLt100KB = file.size / 1024 < 100;
      if (!isLt100KB) {
        toast.warning("File size must be less than 100KB", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      // Upload file to Firebase Storage
      const storageRef = ref(
        storage,
        `/IndividualProfileCompletion/${nanoid()}-${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPdfPercent(progress);
          if (progress === 100) {
            setUploadSuccessMsg("File uploaded successfully.");
          }
        },
        (error) => {
          console.error("File upload error: ", error);
          toast.error("Failed to upload file.", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setDocument(url);
          });
        }
      );
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    setPublish({ ...publish, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const submitHandler = (e) => {
    e.preventDefault();
    const { description } = publish;

    if (description && document) {
      const publishInfo = {
        ...publish,
        skills: skills,
        document,
        type: user.type, // Attach the uploaded document URL
        publish_posted_by_user: user._id,
      };

      dispatch(createPublish(publishInfo)); // Dispatch the createPublish action
      navigate(-1); // Navigate back after successful submission
    } else {
      toast.warning("Please fill all the fields and upload a document", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div>
      <section className="py-2" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0 ">
              <ProfileLogoInd user={user}  />
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PiCertificateDuotone className="text-white" />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Patent Certificates
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/MyPublish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdPublish />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Publications
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/saved-publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <IoMdDownload />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Saved Publish
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="w-full lg:w-[78%] px-4 pt-2">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  <div className="my-4 mx-0">
                    <h2 className="text-lg font-semibold text-[#0F3D79]">
                      Publish Certificate
                    </h2>
                  </div>

                  <form onSubmit={submitHandler}>
                    <div className="mb-6">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="domain"
                      >
                        Domain Names
                      </label>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={Keyareas}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              style={{ marginRight: 2 }}
                              checked={selected}
                            />
                            {option.title}
                          </li>
                        )}
                        value={selectedKeyArea}
                        onChange={(event, newValue) =>
                          handleKeyAreaSelection(newValue)
                        }
                        style={{ width: "100%", marginTop: "10px" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Domains"
                            placeholder="Select up to 4 domains"
                            required={skills.length === 0}
                          />
                        )}
                      />
                    </div>

                    <div className="mb-6">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="summary"
                      >
                        Brief details about Invention
                      </label>
                      <textarea
                        id="summary"
                        name="description"
                        value={publish.description}
                        onChange={handleChange}
                        placeholder="Enter your description"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      ></textarea>
                    </div>

                    <div className="mb-6">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="upload"
                      >
                        Upload a Document
                      </label>
                      <div className="relative flex items-center border-dashed border-2 border-gray-300 rounded p-4">
                        <input
                          id="upload"
                          type="file"
                          accept="application/pdf"
                          onChange={handleFileInputChange}
                          className="absolute inset-0 opacity-0 cursor-pointer"
                        />
                        <div className="flex items-center justify-center w-full h-full text-gray-500">
                          <PictureAsPdfIcon style={{ fontSize: 50 }} />
                          <span className="ml-4 text-lg">
                            Drag & Drop or Browse
                          </span>
                        </div>
                      </div>
                      {pdfPercent > 0 && (
                        <div className="mt-2">
                          <progress value={pdfPercent} max="100" />
                          <span>{pdfPercent}%</span>
                        </div>
                      )}
                      {uploadSuccessMsg && (
                        <div className="mt-2 text-green-600">
                          {uploadSuccessMsg}
                        </div>
                      )}
                    </div>

                    <div className="mb-6">
                      <button
                        type="submit"
                        className="bg-[#0F3D79] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Publish Certificate
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PublishCertificate;
