import React, { useState, useEffect } from "react";
import { InputAdornment, TextField, FormControl } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Autocomplete } from "@mui/joy";
import { useDebounce } from "use-debounce";
import {
  fetchIndividualProfile,
  individualUserSelector,
  sendConnectReq,
  usersToConnect,
} from "../../api/individualUser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import axios from "axios";
import { keyUri } from "../../key";
import {
  category,
  Subcategory,
  Subcategory2,
  Subcategory3,
  Subcategory4,
} from "../../shared/constants";

import premiumlog from "../../images/premiumlogo.png";

function ShowConnect() {
  const { individualtoken, user, people_to_connect } = useSelector(
    individualUserSelector
  );

  const [search, setSearch] = useState("");
  const [data, setData] = useState([]); // Initialize data to hold the users
  const [debouncedText] = useDebounce(search, 1000);

  const dispatch = useDispatch();
  const nav = useNavigate();
  const userId = user?._id;

  const [studentCategory, setCategory] = useState({
    category: "",
  });

  const sub_categories = [
    ...Subcategory,
    ...Subcategory2,
    ...Subcategory3,
    ...Subcategory4,
  ];

  // Fetch initial people to connect on component mount
  useEffect(() => {
    dispatch(usersToConnect(userId));
  }, [dispatch, userId]);

  // Set initial data when `people_to_connect` changes
  useEffect(() => {
    setData(people_to_connect);
  }, [people_to_connect]);

  // Update data based on search input (debounced) and selected category
  useEffect(() => {
    let filteredData = people_to_connect;

    if (debouncedText) {
      // Filter based on search term (name or company)
      filteredData = filteredData.filter((item) =>
        item.name.toLowerCase().includes(debouncedText.toLowerCase())
      );
    }

    if (studentCategory.category) {
      // Further filter based on category
      filteredData = filteredData.filter(
        (item) =>
          item?.profile_completion?.category === studentCategory.category
      );
    }

    setData(filteredData);
  }, [debouncedText, studentCategory.category, people_to_connect]);

  const reqHandle = (senderId, receiverId) => {
    dispatch(sendConnectReq({ senderId, receiverId }, individualtoken));
  };

  const handleFormCategoryChange = (event, newValue) => {
    if (newValue) {
      setCategory({ category: newValue.value });
    } else {
      setCategory({ category: "" });
    }
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <section className="py-0">
        <div className="container mx-auto">
          {/* Header Section with Inputs */}
          <div className=" p-2 flex flex-col lg:flex-row justify-between items-center">
            {/* Left-aligned title */}
            <h1 className="text-lg flex justify-start font-semibold text-[#0F3D79]">
              Explore People
            </h1>

            {/* Right-aligned filter and search inputs */}
            <div className="flex flex-col lg:flex-row items-center ml-auto w-full lg:w-auto space-y-4 lg:space-y-0 lg:space-x-4">
              {/* Autocomplete for category selection */}
              <FormControl className="w-full lg:w-60">
                <Autocomplete
                  placeholder="Select Category"
                  options={category}
                  getOptionLabel={(option) => option.label}
                  onChange={handleFormCategoryChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="border rounded-xl bg-white"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>

              {/* Search input field */}
              <div className="relative w-full  lg:w-60">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon className="text-gray-500" />
                </span>
                <input
                  type="text"
                  placeholder="Search by Name"
                  className="py-2 pl-10 pr-4 w-full rounded-xl border border-gray-300"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* Cards Section */}

          <div className="flex flex-wrap overflow-x-auto pt-4">
            {
              data.length > 0
                ? data.map((item) => (
                    <div
                      key={item?._id}
                      className="w-full lg:w-1/4 p-2 cursor-pointer"
                      onClick={() => nav(`/profile/${item?._id}`)}
                    >
                      <div
                        className="relative p-4 bg-white shadow-md rounded-xl hover:shadow-lg transition-shadow duration-300 flex flex-col items-center"
                        style={{ minHeight: "250px", maxHeight: "250px" }}
                      >
                        {(item?.period === 2 || item?.period === 3) && (
                          <img
                            src={premiumlog} // Replace with the actual URL or path of your top-right image
                            alt="icon"
                            className="absolute top-0 right-2 w-10 h-10" // Adjust width and height as needed
                          />
                        )}

                        <img
                          className="w-24 h-24 object-cover rounded-full border border-gray-200"
                          src={
                            item?.profile_completion?.profile_pic
                              ? item?.profile_completion?.profile_pic
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                          alt="Profile"
                        />
                        {/* <img
                          className="w-24 h-24 object-cover rounded-full border border-gray-200"
                          src={
                            item?.profile_completion?.profile_pic
                              ? item?.profile_completion?.profile_pic
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                          alt="Profile"
                        /> */}
                        <h3
                          className="text-center font-semibold text-lg text-[#0F3D79] mt-2 whitespace-nowrap overflow-hidden text-ellipsis"
                          style={{ maxWidth: "200px" }}
                        >
                          {item?.name}
                        </h3>
                        <p
                          className="text-center text-md text-[#404040] mt-1 mb-3 whitespace-nowrap overflow-hidden text-ellipsis"
                          style={{ maxWidth: "200px" }}
                        >
                          {item?.profile_completion?.category === "Student"
                            ? "Student"
                            : item?.profile_completion?.designation ||
                              "Designation"}
                        </p>
                        <div className="flex justify-center mt-auto">
                          {user?.connections?.find(
                            (connect) =>
                              connect.user === item._id &&
                              connect.status === "request sent"
                          ) ? (
                            <button
                              className="flex items-center justify-center py-1 px-4 bg-white font-semibold text-[#0F3D79] border border-[#0F3D79] rounded-[5px] transition-colors hover:bg-blue-100"
                              style={{ width: "160px", gap: "8px" }}
                            >
                              <CheckCircleIcon fontSize="small" />
                              Request Sent
                            </button>
                          ) : (
                            <button
                              className="flex items-center justify-center py-1 px-4 bg-white font-semibold text-[#0F3D79] border border-[#0F3D79] rounded-[5px] transition-colors hover:bg-blue-100"
                              style={{ width: "160px", gap: "8px" }}
                              onClick={() => reqHandle(userId, item._id)}
                            >
                              <PersonAddIcon fontSize="small" />
                              Connect
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                : null
              // <p>No users to connect</p>
            }
          </div>
        </div>
      </section>
    </div>
  );
}

export default ShowConnect;
