import React, { useEffect, useState } from "react";
import "./Topics.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import WorkIcon from "@mui/icons-material/Work";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { individualUserSelector } from "../../api/individualUser";
import { topicSelector } from "../../api/topic";
import {
  fetchAllTopics,
  updateTopicStatus,
  saveTopic,
  unSaveTopic,
} from "../../api/topic";
import { RWebShare } from "react-web-share";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import AddIcon from "@material-ui/icons/Add";
import nodata from "../../images/no-data.png";
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "@mui/icons-material/FilterList";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import { keyUri } from "../../key";
import DvrIcon from "@mui/icons-material/Dvr";
import { MdOutlineTitle } from "react-icons/md";
import { BsFillBookmarkPlusFill } from "react-icons/bs";

function TopicsDisplays() {
  const dispatch = useDispatch();
  const { individualtoken, user } = useSelector(individualUserSelector);
  const { pathname } = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const { topics } = useSelector(topicSelector);

  // Search filter for topics
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };



  const filteredTopics = topics?.filter((topic) => {
    const topicName = topic?.name?.toLowerCase();
    const postedByName = topic?.topic_posted_by?.name?.toLowerCase();
    const query = searchQuery?.toLowerCase();
    return topicName?.includes(query) || postedByName?.includes(query);
  });

  // Handle saving or unsaving the topic
  const handleSaveTopic = (topicId) => {
   
    if (user?.topics_saved?.some((saved) => saved._id === topicId)) {
      dispatch(unSaveTopic(user?._id, topicId, individualtoken));
    } else {
      dispatch(saveTopic(user?._id, topicId, individualtoken));
    }
  };

  useEffect(() => {
    dispatch(fetchAllTopics());

    const ws = new WebSocket(keyUri.SOCKET_URI);
    ws.onmessage = (event) => {
      const message = JSON.parse(event?.data);
      if (message?.type === "TOPIC_UPDATE") {
        dispatch(
          updateTopicStatus({ id: message?.id, status: message?.status })
        );
      }
    };
    return () => {
      ws.close();
    };
  }, [dispatch]);

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/topics">
                  <div
                    className="flex group py-3 px-2 sm:px-8 items-center hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("topics")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdOutlineTitle />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        All Topics
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Mytopics">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <DvrIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Topics
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/savedTopics">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <BsFillBookmarkPlusFill />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Topics
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="w-full lg:w-[78%] px-4 pt-2">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header with Search Bar, Filter, and Create Topic */}
                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center lg:items-end">
                    <div className="flex-grow flex items-center -mt-1">
                      {/* Heading */}
                      <h2 className="text-xl font-semibold text-[#0F3D79] lg:mr-6 mb-4 lg:mb-3">
                        Topics
                      </h2>
                    </div>

                    {/* Search, Filter, and Create Topic Buttons */}
                    <div className="flex flex-col lg:flex-row justify-end items-center w-full lg:w-auto">
                      {/* Search Bar */}
                      <div className="relative w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon className="text-gray-500" />
                        </span>
                        <input
                          type="text"
                          placeholder="Search Topic Name or Posted by"
                          className="py-2 pl-10 pr-4 w-full lg:w-[300px] rounded-xl border border-gray-300"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>

                      {/* Filter Button */}
                      <div className="w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
                        <button
                          className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full lg:w-auto"
                          type="button"
                        >
                          <FilterIcon /> Filter
                        </button>
                      </div>

                      {/* Create Topic Button */}
                      <div className="w-full lg:w-auto">
                        <Link to="/CreateTopics" className="w-full lg:w-auto">
                          <button
                            className="py-2 px-4 text-white font-semibold border rounded-xl bg-[#0F3D79] w-full lg:w-auto"
                            type="button"
                          >
                            <AddIcon /> Create Topic
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* Topics List */}
                  {filteredTopics?.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                      {filteredTopics
                        .filter((item) => item.topic_posted_by) // Filter out items with null topic_posted_by
                        .map((item) => (
                          <div
                            key={item._id}
                            className="bg-white p-6 rounded-xl shadow-md max-h-[450px] flex flex-col justify-between overflow-hidden h-[400px]"
                          >
                            <Link to={`/TopicView/${item._id}`}>
                              <div>
                                <div className="flex items-center mb-4">
                                  <img
                                    src={
                                      item?.topic_posted_by?.profile_completion
                                        ?.profile_pic ||
                                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                    }
                                    alt="Profile"
                                    className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-cover"
                                  />
                                  <div>
                                    <h3 className="text-[#0F3D79] text-md font-semibold">
                                      {item?.topic_posted_by?.name || ""}
                                    </h3>
                                    <p className="text-sm text-gray-500">
                                      {item?.topic_posted_by?.profile_completion
                                        ?.designation || ""}
                                    </p>
                                  </div>
                                </div>
                                <h4 className="text-[#0F3D79] font-semibold mb-2">
                                  {item?.name || ""}
                                </h4>
                                <p className="text-sm text-gray-600 mb-4 line-clamp-6">
                                  {item?.summary || ""}
                                </p>
                              </div>
                              <div className="flex justify-center items-center h-32 bg-gray-100 rounded-lg">
                                <PictureAsPdfIcon
                                  style={{ fontSize: 50 }}
                                  className="text-blue-600"
                                />
                              </div>
                            </Link>
                            <div className="flex items-center mt-4 gap-x-4 md:gap-x-[10px]">
                              <RWebShare
                                data={{
                                  text: "Check out this topic",
                                  url: `https://user.bharc.in/topic_view/${item._id}`,
                                  title: "Topic Share",
                                }}
                                onClick={() =>
                                  console.log("Shared successfully!")
                                }
                              >
                                <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                  Share
                                  <ShareIcon className="ml-2 text-[#346BB7]" />
                                </button>
                              </RWebShare>
                              {user?.topics_saved?.some(
                                (saved) => saved._id === item._id
                              ) ? (
                                <button
                                  className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                  onClick={() => handleSaveTopic(item._id)}
                                >
                                  Saved
                                  <BookmarkBorderOutlinedIcon
                                    className="ml-2"
                                    style={{ fill: "white" }}
                                  />
                                </button>
                              ) : (
                                <button
                                  className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                  onClick={() => handleSaveTopic(item._id)}
                                >
                                  Save
                                  <BookmarkBorderOutlinedIcon
                                    className="ml-2"
                                    style={{ fill: "#346BB7" }}
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center mt-20">
                      {/* <img src={nodata} alt="No Data" className="w-72 h-72" /> */}
                      <p className="text-center text-gray-500 mt-4">
                        No topics found
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TopicsDisplays;
