import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import { useEffect } from "react";
import { updateIndividual } from "../../api/individualUser";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import { ModalDialog } from "@mui/joy";
import {
  FormControl,
  Grid,
  InputLabel,
  Menu,
  OutlinedInput,
  Select,
  MenuItem,
} from "@material-ui/core";
import Button from "@mui/joy/Button";
import { subCategory } from "../../shared/constants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { FormHelperText } from "@mui/material";
import { toast } from "react-toastify";

function EducationDetails({ user, individualtoken }) {
  const [start_year1, setStart_year1] = useState(0);
  const [end_year1, setEnd_year1] = useState(0);
  const [start_year2, setStart_year2] = useState(0);
  const [end_year2, setEnd_year2] = useState(0);
  const [currentlyStudying, setCurrentlyStudying] = useState(false);


  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);

  const dispatch = useDispatch();
  const [open2, setOpen2] = useState(false);
  const [eduForm, setEduForm] = useState({
    institute_name1: "",
    education_level1: "",
    course_name1: "",
    start_year1: "",
    end_year1: "",
    institute_name2: "",
    education_level2: "",
    course_name2: "",
    start_year2: "",
    end_year2: "",
  });

  useEffect(() => {
    preFill();
  }, [user]);

  const preFill = () => {
    setEduForm({
      institute_name1:
        user?.profile_completion?.education_details_1?.institute_name1 || "",
      education_level1:
        user?.profile_completion?.education_details_1?.education_level1 || "",
      course_name1:
        user?.profile_completion?.education_details_1?.course_name1 || "",
      institute_name2:
        user?.profile_completion?.education_details_2?.institute_name2 || "",
      education_level2:
        user?.profile_completion?.education_details_2?.education_level2 || "",
      course_name2:
        user?.profile_completion?.education_details_2?.course_name2 || "",
    });
    setStart_year1(
      user?.profile_completion?.education_details_1?.start_year1 || null
    );
    setEnd_year1(
      user?.profile_completion?.education_details_1?.end_year1 == "present"
        ? null
        : user?.profile_completion?.education_details_1?.end_year1
    );
    setCurrentlyStudying(
      user?.profile_completion?.education_details_1?.end_year1 == "present"
    );
    setStart_year2(
      user?.profile_completion?.education_details_2?.start_year2 || null
    );
    setEnd_year2(
      user?.profile_completion?.education_details_2?.end_year2 || null
    );
  };

  

  const handelClose2 = () => {
    setOpen2(false);
    setError1(false);
    setError2(false);
    setError3(false);
    setError4(false);
    preFill();
  };

  const isInCurrentYear = (date) => date.get("year") > dayjs().get("year");

  //education change handle
  const handelEduFormChange = (event) => {
    const { name, value } = event.target;

    setEduForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //education detail submit function
  // const submitEducation = (e) => {
  //   e.preventDefault();
  //   if (error1 || error2 || error3 || error4) {
  //     toast.warning("Please fill valid year", {
  //       position: "top-center",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeButton: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   } else {
  //     const {
  //       institute_name1,
  //       education_level1,
  //       course_name1,
  //       institute_name2,
  //       education_level2,
  //       course_name2,
  //     } = eduForm;
  //     const education_details = {
  //       education_details_1: {
  //         institute_name1,
  //         education_level1,
  //         course_name1,
  //         start_year1,
  //         end_year1,
  //       },
  //       education_details_2: {
  //         institute_name2,
  //         education_level2,
  //         course_name2,
  //         start_year2,
  //         end_year2,
  //       },
  //     };
  //     dispatch(
  //       updateIndividual(user?._id, { education_details }, individualtoken)
  //     );
  //     handelClose2();
  //   }
  // };
  const submitEducation = (e) => {
    e.preventDefault();
    if (error1 || error2 || error3 || error4) {
      toast.warning("Please fill valid years", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const {
        institute_name1,
        education_level1,
        course_name1,
        institute_name2,
        education_level2,
        course_name2,
      } = eduForm;

      const education_details = {
        education_details_1: {
          institute_name1,
          education_level1,
          course_name1,
          start_year1,
          end_year1: currentlyStudying ? "present" : end_year1, // Handle "present"
        },
        education_details_2: {
          institute_name2,
          education_level2,
          course_name2,
          start_year2,
          end_year2,
        },
      };

      dispatch(
        updateIndividual(user?._id, { education_details }, individualtoken)
      );
      handelClose2();
    }
  };



  //date picker functions
  const handelEduYear1 = (event) => {
    setError1(false);
    if (event == null || event == undefined) {
      setStart_year1(0);
    } else {
      setStart_year1(event);
      setError1(false);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError1(true);
    }
  };

  const handelEduYear2 = (event) => {
    setError2(false);
    if (event == null || event == undefined) {
      setEnd_year1(0);
    } else {
      setEnd_year1(event);
      setError2(false);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError2(true);
    }
  };

  const handelEduYear3 = (event) => {
    setError3(false);
    if (event == null || event == undefined) {
      setStart_year2(0);
    } else {
      setStart_year2(event);
      setError3(false);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError3(true);
    }
  };

  const handelEduYear4 = (event) => {
    setError4(false);
    if (event == null || event == undefined) {
      setEnd_year2(0);
    } else {
      setEnd_year2(event);
      setError4(false);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError4(true);
    }
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <div class="flex flex-wrap -mx-2 -mb-2 justify-between items-center">
        <div class="w-full flex justify-between items-end">
          <h3 class="text-lg ml-1 font-semibold text-[#0F3D79]">
            Education Details
          </h3>

          <button
            class="text-gray-300  hover:text-gray-500"
            onClick={() => setOpen2(true)}
          >
            <EditIcon />
          </button>
        </div>

        {/* {user?.profile_completion?.education_details_1?.institute_name1 == "" ||
        user?.profile_completion?.education_details_2?.institute_name2 == "" ? (
          <button class="w-auto mb-3 px-1 text-gray-500">
            <AddIcon onClick={() => setOpen2(true)} />
          </button>
        ) : (
          <button class="w-auto  text-gray-300 hover:text-gray-500">
            <EditIcon onClick={() => setOpen2(true)} />
          </button>
        )} */}
      </div>

      <div className="mt-5 pt-4 text-md font-medium text-gray-500 ">
        <p className="font-semibold" style={{ color: "#000" }}>
          {user?.profile_completion?.education_details_1?.institute_name1}
        </p>
        <p>{user?.profile_completion?.education_details_1?.education_level1}</p>
        <p>{user?.profile_completion?.education_details_1?.course_name1}</p>
        <p>
          {
            user?.profile_completion?.education_details_1?.start_year1 &&
            user?.profile_completion?.education_details_1?.end_year1
              ? `${dayjs(
                  user.profile_completion.education_details_1.start_year1
                ).format("YYYY")}-${
                  user.profile_completion.education_details_1.end_year1 ===
                  "present"
                    ? "Present" // Handle "present" case
                    : dayjs(
                        user.profile_completion.education_details_1.end_year1
                      ).format("YYYY")
                }`
              : "" // Display nothing
          }
        </p>
      </div>

      <div className="mt-5 text-md font-medium text-gray-500">
        <p class="font-semibold" style={{ color: "#000" }}>
          {user?.profile_completion?.education_details_2?.institute_name2}
        </p>
        <p>{user?.profile_completion?.education_details_2?.education_level2}</p>
        <p>{user?.profile_completion?.education_details_2?.course_name2}</p>
        <p>
          {
            user?.profile_completion?.education_details_2?.start_year2 &&
            user?.profile_completion?.education_details_2?.end_year2
              ? `${dayjs(
                  user?.profile_completion?.education_details_2?.start_year2
                ).format("YYYY")}-${dayjs(
                  user?.profile_completion?.education_details_2?.end_year2
                ).format("YYYY")}`
              : "" //display nothing
          }
        </p>
      </div>
      <>
        <Modal open={open2}>
          <ModalDialog
            aria-labelledby="nested-modal-title"
            aria-describedby="nested-modal-description"
            // sx={(theme) => ({
            //   [theme.breakpoints.only("xs")]: {
            //     top: "unset",
            //     bottom: 0,
            //     left: 0,
            //     right: 0,
            //     borderRadius: 0,
            //     transform: "none",
            //     maxWidth: "unset",
            //   },
            // })}
            sx={(theme) => ({
              [theme.breakpoints.only("xs")]: {
                position: "absolute",
                overflowY: "auto",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "100%",
                width: "90%",
                maxHeight: "90%",
              },
            })}
          >
            <h2 class="text-md font-semibold text-[#0F3D79]">
              Latest Education
            </h2>

            <div style={{ marginTop: "10px" }}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Institution name </InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Institution name "
                  name="institute_name1" // Make sure to set the name attribute
                  value={eduForm.institute_name1}
                  onChange={handelEduFormChange}
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div>

            <div>
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                margin="normal"
                style={{ marginTop: "10px" }}
              >
                <InputLabel>Course selection</InputLabel>
                <Select
                  required
                  name="education_level1"
                  label="Course Selection"
                  size="small"
                  value={eduForm.education_level1}
                  onChange={handelEduFormChange}
                >
                  {subCategory.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ marginTop: "5px" }}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Course name </InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Course name"
                  name="course_name1" // Make sure to set the name attribute
                  value={eduForm.course_name1}
                  onChange={handelEduFormChange}
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div>

            <div className="mt-3">
              <Grid container spacing={2}>
                {/* Start Year Field */}
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} required>
                      <DatePicker
                        name="start_year1"
                        slotProps={{
                          textField: { size: "small", error: error1 },
                        }}
                        label="Start Year"
                        views={["year", "month"]}
                        value={dayjs(start_year1)}
                        onChange={(date) => {
                          setStart_year1(date);
                          setError1(false);
                        }}
                        format="MM/YYYY"
                        inputFormat="MM/YYYY"
                        shouldDisableYear={isInCurrentYear}
                      />
                      {error1 && (
                        <FormHelperText error={error1}>
                          Invalid Start Year
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </FormControl>
                </Grid>

                {/* End Year Field */}
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} required>
                      <DatePicker
                        name="end_year1"
                        slotProps={{
                          textField: { size: "small", error: error2 },
                        }}
                        label="End Year"
                        views={["year", "month"]}
                        value={currentlyStudying ? null : dayjs(end_year1)}
                        onChange={(date) => {
                          setEnd_year1(date);
                          setError2(false);
                        }}
                        format="MM/YYYY"
                        inputFormat="MM/YYYY"
                        shouldDisableYear={isInCurrentYear}
                        disabled={currentlyStudying} // Disable if checkbox is checked
                      />
                      {error2 && (
                        <FormHelperText error={error2}>
                          Invalid End Year
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </FormControl>
                  <div style={{ marginTop: "10px" }}>
                    <label>
                      <input
                        type="checkbox"
                        checked={currentlyStudying}
                        onChange={(e) => {
                          setCurrentlyStudying(e.target.checked);
                          if (e.target.checked) setEnd_year1("present"); // Set value to "present"
                        }}
                      />
                      Currently Studying
                    </label>
                  </div>
                </Grid>
              </Grid>

              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} required>
                      <DatePicker
                        name="start_year1"
                        slotProps={{
                          textField: { size: "small", error: error1 },
                        }}
                        label="Start year"
                        views={["year", "month"]}
                        value={dayjs(start_year1)}
                        onChange={handelEduYear1}
                        format="MM/YYYY"
                        inputFormat="MM/YYYY"
                        // onChange={(e) => {
                        //   e ? setStart_year1(e) : setStart_year1(null);
                        // }}
                        shouldDisableYear={isInCurrentYear}
                      /> 

                      currently Studying
                      {error1 && (
                        <FormHelperText error={error1}>
                          Invalid year
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} required>
                      <DatePicker
                        format="MM/YYYY"
                        inputFormat="MM/YYYY"
                        name="end_year1"
                        slotProps={{
                          textField: { size: "small", error: error2 },
                        }}
                        label="End year"
                        views={["year", "month"]}
                        value={dayjs(end_year1)}
                        onChange={handelEduYear2}
                        shouldDisableYear={isInCurrentYear}
                      />
                      {error2 && (
                        <FormHelperText error={error2}>
                          Invalid year
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid> */}
            </div>
            <h2 class="text-md font-semibold text-[#0F3D79] py-3">
              Previous Education
            </h2>

            <div>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Institution name </InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Institution name "
                  name="institute_name2" // Make sure to set the name attribute
                  value={eduForm.institute_name2}
                  onChange={handelEduFormChange}
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div>

            <div>
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                margin="normal"
                style={{ marginTop: "10px" }}
              >
                <InputLabel>Course selection</InputLabel>
                <Select
                  required
                  name="education_level2"
                  label="Course Selection"
                  size="small"
                  value={eduForm.education_level2}
                  onChange={handelEduFormChange}
                >
                  {subCategory.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ marginTop: "5px" }}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Course name </InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Course name"
                  name="course_name2" // Make sure to set the name attribute
                  value={eduForm.course_name2}
                  onChange={handelEduFormChange}
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div>

            <div className="mt-3">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} required>
                      <DatePicker
                        name="start_year2"
                        slotProps={{
                          textField: { size: "small", error: error3 },
                        }}
                        format="MM/YYYY"
                        inputFormat="MM/YYYY"
                        label="Start year"
                        views={["year", "month"]}
                        value={dayjs(start_year2)}
                        onChange={handelEduYear3}
                        shouldDisableYear={isInCurrentYear}
                      />
                      {error3 && (
                        <FormHelperText error={error3}>
                          Invalid year
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} required>
                      <DatePicker
                        name="end_year2"
                        slotProps={{
                          textField: { size: "small", error: error4 },
                        }}
                        format="MM/YYYY"
                        inputFormat="MM/YYYY"
                        label="End year"
                        views={["year", "month"]}
                        value={dayjs(end_year2)}
                        onChange={handelEduYear4}
                        shouldDisableYear={isInCurrentYear}
                      />
                      {error4 && (
                        <FormHelperText error={error4}>
                          Invalid year
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
              <div className="flex justify-end  mt-4 ">
                <Button
                  variant="outlined"
                  color="neutral"
                  onClick={handelClose2}
                >
                  Cancel{" "}
                </Button>
                <Button
                  variant="solid"
                  color="contained"
                  style={{
                    backgroundColor: "#0066A2",
                    color: "white",

                    marginLeft: "10px",
                  }}
                  onClick={submitEducation}
                >
                  Submit{" "}
                </Button>
              </div>
            </div>
          </ModalDialog>
        </Modal>
      </>
    </div>
  );
}

export default EducationDetails;
