import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@mui/material/Modal";
import { ModalDialog, Textarea } from "@mui/joy";
import { useDispatch } from "react-redux";
import { updateIndividual } from "../../api/individualUser";
import Button from "@mui/joy/Button";
import { TextField, Box, Typography } from "@mui/material";
import { toast } from "react-toastify";

function Awards({ user, individualtoken }) {
  const dispatch = useDispatch();
  const [open4, setOpen4] = useState(false);
  const [skill_year1, setskill_year1] = useState("");
  const [skill_year2, setskill_year2] = useState("");
  const [skill, setskill] = useState({
    skill1: "",
  });
  const [skillec, setskillec] = useState({
    skill2: "",
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    preFill();
  }, [user]);

  const preFill = () => {
    setskill({
      skill1: user?.profile_completion?.skill_details1?.skill1 || "",
    });
    setskillec({
      skill2: user?.profile_completion?.skill_details2?.skill2 || "",
    });
    setskill_year1(user?.profile_completion?.skill_details1?.skill_year1 || "");
    setskill_year2(user?.profile_completion?.skill_details2?.skill_year2 || "");
  };

  const handelClose4 = () => {
    setOpen4(false);
    setError(false);
    preFill();
  };

  const handelskillChange = (event) => {
    const { value } = event.target;
    if (value.length <= 200) {
      setskill({ skill1: value });
    }
  };

  const handelskillChangeSec = (event) => {
    const { value } = event.target;
    if (value.length <= 200) {
      setskillec({ skill2: value });
    }
  };

  const handleSkillYear1Change = (event) => {
    setskill_year1(event.target.value);
  };

  const handleSkillYear2Change = (event) => {
    setskill_year2(event.target.value);
  };

  const skillumbit = (e) => {
    const { skill1 } = skill;
    const { skill2 } = skillec;

    if (!skill1 && !skill2) {
      toast.warning("Please provide at least one skill detail", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    e.preventDefault();

    const skill_details = {
      skill_details1: {
        skill1,
        skill_year1,
      },
      skill_details2: {
        skill2,
        skill_year2,
      },
    };
    dispatch(updateIndividual(user?._id, { skill_details }, individualtoken));
    handelClose4();
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <div className="mb-5 p-4 bg-gray-100 rounded-xl h-[365px]">
        <div className="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
          <div className="w-auto px-4">
            <h3 className="text-lg font-semibold text-[#0F3D79] mb-4">
              Skills
            </h3>
          </div>
          {user?.profile_completion?.skill_details1?.skill1 === "" ? (
            <button className="w-auto px-2 mb-4 text-gray-500">
              <AddIcon onClick={() => setOpen4(true)} />
            </button>
          ) : (
            <button className="w-auto px-2 mb-4 text-gray-300 hover:text-gray-500">
              <EditIcon onClick={() => setOpen4(true)} />
            </button>
          )}
        </div>
        <div className="mt-2">
          <p className="text-md font-semibold text-gray-800 py-2">
            {user?.profile_completion?.skill_details1?.skill_year1}
          </p>
        </div>
        <div className="flex mt-1">
          {user?.profile_completion?.skill_details1?.skill1 ? (
            <h4 className="text-md text-[#0F3D79] font-semibold"> Details: </h4>
          ) : null}
          <p className="ml-2 text-md font-semibold text-gray-800">
            {user?.profile_completion?.skill_details1?.skill1}
          </p>
        </div> <br/>
        <div className="mt-2">
          <p className="text-md font-semibold text-gray-800 py-2">
            {user?.profile_completion?.skill_details2?.skill_year2}
          </p>
        </div>
        <div className="flex mt-1">
          {user?.profile_completion?.skill_details2?.skill2 ? (
            <h4 className="text-md text-[#0F3D79] font-semibold"> Details: </h4>
          ) : null}
          <p className="ml-2 text-md font-semibold text-gray-800">
            {user?.profile_completion?.skill_details2?.skill2}
          </p>
        </div>
      </div>

      <Modal open={open4}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              position: "absolute",
              overflowY: "auto",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "100%",
              width: "100%",
              maxHeight: "100%",
            },
          })}
        >
          <h2 className="text-md text-[#0F3D79] font-semibold py-2">
            Add Skill 1
          </h2>
          <div style={{ marginTop: "20px" }}>
            <TextField
              name="skill_year1"
              size="small"
              label="Skill Name"
              value={skill_year1}
              onChange={handleSkillYear1Change}
              variant="outlined"
              fullWidth
            />
          </div>{" "}
          <br />
          <Textarea
            placeholder="Type here…"
            value={skill.skill1}
            onChange={handelskillChange}
            minRows={2}
            maxRows={4}
            endDecorator={
              <Box
                sx={{
                  display: "flex",
                  gap: "var(--Textarea-paddingBlock)",
                  pt: "var(--Textarea-paddingBlock)",
                  borderTop: "1px solid",
                  borderColor: "divider",
                  flex: "auto",
                }}
              >
                <Typography
                  level="body-xs"
                  sx={{
                    ml: "auto",
                    color: skill.skill1.length < 200 ? "black" : "red",
                  }}
                >
                  {skill.skill1.length < 200
                    ? `${skill.skill1.length} character(s)`
                    : "Your description cannot exceed 200 characters."}
                </Typography>
              </Box>
            }
            sx={{ minWidth: 500 }}
          />
          <h2 className="text-md text-[#0F3D79] font-semibold py-2">
            Add Skill 2
          </h2>
          <div style={{ marginTop: "20px" }}>
            <TextField
              name="skill_year2"
              size="small"
              label="Skill Name"
              value={skill_year2}
              onChange={handleSkillYear2Change}
              variant="outlined"
              fullWidth
            />
          </div>{" "}
          <br></br>
          <Textarea
            placeholder="Type here…"
            value={skillec.skill2}
            onChange={handelskillChangeSec}
            minRows={2}
            maxRows={4}
            endDecorator={
              <Box
                sx={{
                  display: "flex",
                  gap: "var(--Textarea-paddingBlock)",
                  pt: "var(--Textarea-paddingBlock)",
                  borderTop: "1px solid",
                  borderColor: "divider",
                  flex: "auto",
                }}
              >
                <Typography
                  level="body-xs"
                  sx={{
                    ml: "auto",
                    color: skillec.skill2.length < 200 ? "black" : "red",
                  }}
                >
                  {skillec.skill2.length < 200
                    ? `${skillec.skill2.length} character(s)`
                    : "Your description cannot exceed 200 characters."}
                </Typography>
              </Box>
            }
            sx={{ minWidth: 500 }}
          />
          <div className="flex justify-end mt-4">
            <Button variant="outlined" color="neutral" onClick={handelClose4}>
              Cancel
            </Button>
            <Button
              variant="solid"
              color="contained"
              style={{
                backgroundColor: "#0066A2",
                color: "white",
                marginLeft: "10px",
              }}
              onClick={skillumbit}
            >
              Submit
            </Button>
          </div>
        </ModalDialog>
      </Modal>
    </div>
  );
}

export default Awards;
