import React from 'react'
import ProfileLogoInd from '../UniversalComp/ProfileLogoInd'
import { useDispatch, useSelector } from 'react-redux'
import {  individualUserSelector } from '../../api/individualUser'
import { useState } from 'react'
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import AllNotifications from './AllNotifications'
import { useEffect } from 'react'
import Requests from './Requests'
import Accepts from './Accepts'

function Index() {
    const dispatch = useDispatch()
    const {individualtoken, user} = useSelector(individualUserSelector)
    const [type, setType] = useState('')
    const [requests, setRequests] = useState([])
    const [accepts, setAccepts] = useState([])


    useEffect(()=>{
      const requsets = user?.notifications?.filter((item)=>{
        return (
          item?.requestType == "accept"
        )
      })
      setRequests(requsets)

      const accepts = user?.notifications?.filter((item)=>{
        return (
          item?.requestType == "requested"
        )
      })
      setAccepts(accepts)
    },[user])


  return (
    <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div class="py-5  bg-gray-100 rounded-xl overflow-hidden">
                <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  onClick={() => setType("")}
                  style={{
                    backgroundColor:
                      type == "" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <PeopleAltIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Notifications
                    </h4>
                  </div>
                </div>
                {/* <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  onClick={() => setType("request")}
                  style={{
                    backgroundColor:
                      type == "request" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 hover:bg-[#0F3D79] text-blue-50 rounded-full">
                    <PeopleAltIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Connection Request
                    </h4>
                  </div>
                </div> */}
                {/* <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  onClick={() => setType("accept")}
                  style={{
                    backgroundColor:
                      type == "accept" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 hover:bg-[#0F3D79] text-blue-50 rounded-full">
                    <PeopleAltIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Connection Accepted
                    </h4>
                  </div>
                </div> */}
              </div>
            </div>

            {/* <div
              class="w-full lg:w-[78%] px-3"
              style={{
                display:
                  type === ""
                    ? "block"
                    : "none",
              }}
            > */}
                        <div class="w-full lg:w-[78%] px-4 pt-8">
              <div class="h-full  py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div style={{ display: type == "" ? "block" : "none" }}  >
                  <AllNotifications notifications={user?.notifications}  />
                </div>
                <div style={{ display: type == "request" ? "block" : "none" }}>
                  <Requests requests={requests} user={user}/>
                </div>
                <div
                  style={{ display: type == "accept" ? "block" : "none" }}
                >
                  <Accepts accepts={accepts} user={user}/>
                </div>
              </div>
              </div>
          </div>
        </div>
      </section>
  )
}

export default Index