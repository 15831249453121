import { Routes, Route } from "react-router-dom";
import Login from "./pages/loginauth/login";
import SignUpIndividual from "./pages/signauth/signUpIndividual";
import PrivateRoutes from "./shared/privateRoute";
import VerifyPhone from "./pages/signauth/verifyPhoneNew";
import Profile from "./pages/profile";
import Home from "./pages/home/index";
import Navbar from "./components/index";
import Account from "./pages/account";
import Connection from "./pages/connect/connection";
import Jobs from "./pages/jobs";
import AppliableJobs from "./pages/jobs/AppliableJobs";
import RequestReceived from "./pages/connect/requestReceived";
import Connected from "./pages/connect/connected";
import ShowConnect from "./pages/connect/showConnect";
import IoiProfilepage from "./pages/connect/IoiProfilepage";
import IndividualProfile from "./pages/connect/IndividualProfile";
import ResetPassword from "./pages/loginauth/resetPassword";
import ForgotPassword from "./pages/loginauth/forgotPassword";
import NotFound from "./shared/notFound";
import Notifications from "./pages/Notifications";
import Privacypolicy from "./shared/privacypolicy";
import Useragreement from "./shared/useragreement";
import Jobapplied from "./pages/jobs/jobapplied";
import Jobsaved from "./pages/jobs/jobsaved";
import Broadcast from "./pages/Broadcast/broadcast";
import AI from "./pages/Broadcast/AI";
import DreamTrue from "./pages/Broadcast/DreamTrue";
import Bharc from "./pages/Broadcast/bharc";
import Explore from "./pages/explore/index";
import Publish from "./pages/publish/index";
import AddPublish from "./pages/publish/AddPublish";
import Topics from "./pages/topics/index";
import Addtopics from "./pages/topics/Addtopics";
import CompanyTab from "./pages/connect/companyTab";
import JobShortlisted from "./pages/jobs/JobShortlisted";
import MyTopics from "./pages/topics/Mytopics";
import CreateTopics from "./pages/topics/CreateTopic";
import TopicView from "./pages/topics/TopicView";
import MyConnections from "./pages/connect/MyConnection";
import Competitions from "./pages/explore/Competitions";
import OtherEvents from "./pages/explore/OtherEvents";
import PublishView from "./pages/publish/publishView";
import PublishCertificate from "./pages/publish/PublishCertificate";
import MyUploads from "./pages/publish/MyUploads";
import MyRequest from "./pages/connect/MyRequest";
import CreateAdd from "./pages/explore/createAdd";
import ViewAds from "./pages/explore/viewAdd";
import SavedAds from "./pages/explore/savedAdd";
import Exploreed from "./pages/explore/explore";
import { useState } from "react";
import Add from "./pages/explore/ads";
import SavedTopics from "../src/pages/topics/saveTopics";
import SavedPublish from "../src/pages/publish/savedPublish";
import SavedEvents from "./pages/explore/SavedEvents";
import ViewConferences from "./pages/explore/ViewConferences";
import ViewCompetitions from "./pages/explore/ViewCompetitions";
import ViewOtherEvents from "./pages/explore/ViewOtherEvents";

import Generaldocuments from "./pages/publish/Generaldocuments";

function App() {
  const [route, setRoute] = useState(true);
  return (
    <div className="App">
      <Routes>
        <Route index element={<Login />} />
        <Route path="/sign-up" element={<SignUpIndividual />} />
        <Route path="/privacy" element={<Privacypolicy />} />
        <Route path="/agreement" element={<Useragreement />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/topic_view/:id" element={<TopicView />} />
        <Route path="/publish_view/:id" element={<PublishView />} />

        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<Home />} />
          <Route
            path="/verify-phone"
            element={
              <Navbar>
                <VerifyPhone />
              </Navbar>
            }
          />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/myaccount"
            element={
              <Navbar>
                <Account />
              </Navbar>
            }
          />
          <Route
            path="/connection"
            element={
              <Navbar>
                <Connection />
              </Navbar>
            }
          />
          <Route
            path="/connection/company"
            element={
              <Navbar>
                <Connection />
              </Navbar>
            }
          />
          <Route
            path="/requests"
            element={
              <Navbar>
                <RequestReceived />
              </Navbar>
            }
          />
          <Route
            path="/connects"
            element={
              <Navbar>
                <Connected />
              </Navbar>
            }
          />
          <Route
            path="/people/:id"
            element={
              <Navbar>
                <ShowConnect />
              </Navbar>
            }
          />
          <Route
            path="/jobs"
            element={
              <Navbar>
                <Jobs />
              </Navbar>
            }
          />
          <Route
            path="/joblist/:id"
            element={
              <Navbar>
                <AppliableJobs />
              </Navbar>
            }
          />
          <Route
            path="/view-ioiprofile/:id"
            element={
              <Navbar>
                <IoiProfilepage />
              </Navbar>
            }
          />
          <Route
            path="/profile/:id"
            element={
              <Navbar>
                <IndividualProfile />
              </Navbar>
            }
          />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/notifications"
            element={
              <Navbar>
                <Notifications />
              </Navbar>
            }
          />
          <Route
            path="/jobapplied"
            element={
              <Navbar>
                <Jobapplied />
              </Navbar>
            }
          />
          <Route
            path="/jobsave"
            element={
              <Navbar>
                <Jobsaved />
              </Navbar>
            }
          />
          <Route
            path="/domesticSolutions"
            element={
              <Navbar>
                <Broadcast />
              </Navbar>
            }
          />
          <Route
            path="/AI"
            element={
              <Navbar>
                <AI />
              </Navbar>
            }
          />
          <Route
            path="/dreamTrue"
            element={
              <Navbar>
                <DreamTrue />
              </Navbar>
            }
          />
          <Route
            path="/bharc"
            element={
              <Navbar>
                <Bharc />
              </Navbar>
            }
          />
          <Route
            path="/explore"
            element={
              <Navbar>
                <Explore />
              </Navbar>
            }
          />
          <Route
            path="/publish"
            element={
              <Navbar>
                <Publish />
              </Navbar>
            }
          />
          <Route
            path="/saved-publish"
            element={
              <Navbar>
                <SavedPublish />
              </Navbar>
            }
          />
          <Route
            path="/Addpublish"
            element={
              <Navbar>
                <AddPublish />
              </Navbar>
            }
          />
          <Route
            path="/topics"
            element={
              <Navbar>
                <Topics />
              </Navbar>
            }
          />
          <Route
            path="/Addtopics"
            element={
              <Navbar>
                <Addtopics />
              </Navbar>
            }
          />
          <Route
            path="/JobShortlisted"
            element={
              <Navbar>
                <JobShortlisted />
              </Navbar>
            }
          />
          <Route
            path="/MyTopics"
            element={
              <Navbar>
                <MyTopics />
              </Navbar>
            }
          />
          <Route
            path="/CreateTopics"
            element={
              <Navbar>
                <CreateTopics />
              </Navbar>
            }
          />
          <Route
            path="/TopicView/:id"
            element={
              <Navbar>
                <TopicView route={route} />
              </Navbar>
            }
          />

          <Route
            path="/Competitions"
            element={
              <Navbar>
                <Competitions />
              </Navbar>
            }
          />
          <Route
            path="/OtherEvents"
            element={
              <Navbar>
                <OtherEvents />
              </Navbar>
            }
          />
          <Route
            path="/PublishView/:id"
            element={
              <Navbar>
                <PublishView route={route} />
              </Navbar>
            }
          />
          <Route
            path="/PublishCertificate"
            element={
              <Navbar>
                <PublishCertificate />
              </Navbar>
            }
          />
          <Route
            path="/Generaldocuments"
            element={
              <Navbar>
                <Generaldocuments />
              </Navbar>
            }
          />
       
        
          <Route
            path="/MyUploads"
            element={
              <Navbar>
                <MyUploads />
              </Navbar>
            }
          />
          <Route
            path="/MyRequest"
            element={
              <Navbar>
                <MyRequest />
              </Navbar>
            }
          />
          <Route
            path="/MyConnections"
            element={
              <Navbar>
                <MyConnections />
              </Navbar>
            }
          />
          <Route
            path="/CreateAdd"
            element={
              <Navbar>
                <CreateAdd />
              </Navbar>
            }
          />
          <Route
            path="/ViewAds/:id"
            element={
              <Navbar>
                <ViewAds />
              </Navbar>
            }
          />
          <Route
            path="/SavedAds"
            element={
              <Navbar>
                <SavedAds />
              </Navbar>
            }
          />
          <Route
            path="/explore-new"
            element={
              <Navbar>
                <Exploreed />
              </Navbar>
            }
          />
          <Route
            path="/add"
            element={
              <Navbar>
                <Add />
              </Navbar>
            }
          />
          <Route
            path="/SavedTopics"
            element={
              <Navbar>
                <SavedTopics />
              </Navbar>
            }
          />
          <Route
            path="/SavedEvents"
            element={
              <Navbar>
                <SavedEvents />
              </Navbar>
            }
          />
          <Route
            path="/ViewConferences"
            element={
              <Navbar>
                <ViewConferences />
              </Navbar>
            }
          />

          <Route
            path="/ViewCompetitions"
            element={
              <Navbar>
                <ViewCompetitions />
              </Navbar>
            }
          />
          <Route
            path="/ViewOtherEvents"
            element={
              <Navbar>
                <ViewOtherEvents />
              </Navbar>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
