import React, { useEffect, useState } from "react";
import "./Explore.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import ExploreIcon from "@mui/icons-material/Explore";
import { GiTeamIdea } from "react-icons/gi";
import { PiExamBold } from "react-icons/pi";
import { MdOutlineEmojiEvents } from "react-icons/md";
import { LiaBuysellads } from "react-icons/lia";
import { GiSaveArrow } from "react-icons/gi";
import { FaDownload } from "react-icons/fa6";
import { individualUserSelector } from "../../api/individualUser";
import {
  exploreSelector,
  fetchAllExplore,
  getCurrentExploreSuccessfull,
  saveExplore,
  unSaveExplore,
} from "../../api/explore";
import noData from "../../images/no-data.png";
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "@mui/icons-material/FilterList";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import SaveIcon from "@mui/icons-material/Save";
import { RWebShare } from "react-web-share";
import { Key } from "@mui/icons-material";
import { keyUri } from "../../key";

const useStyles = makeStyles(() => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
}));

function OtherEvents() {
  const { user, individualtoken } = useSelector(individualUserSelector);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { explore } = useSelector(exploreSelector);
  const [upcoming, setUpcoming] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(fetchAllExplore());
    const ws = new WebSocket(keyUri.SOCKET_URI); // Update with your actual WebSocket URL
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "EVENT_CREATED") {
        dispatch(getCurrentExploreSuccessfull({ currentExplore: explore }));
      }
    };
    return () => {
      ws.close();
    };
  }, [explore, dispatch]);

  const handleSaveTopic = (exploreId) => {
    if (user?.explore_saved?.some((saved) => saved._id === exploreId)) {
      dispatch(unSaveExplore(user?._id, exploreId, individualtoken));
    } else {
      dispatch(saveExplore(user?._id, exploreId, individualtoken));
    }
  };

  useEffect(() => {
    // Filter upcoming other events based on the date, search query, and event type
    const currentDate = new Date();
    const filterUpcoming = explore.filter((event) => {
      const eventDate = new Date(event.date);
      const eventName = event?.explore_posted_by_ioi?.name.toLowerCase() || "";
      const query = searchQuery.toLowerCase();

      // Check if the event date is in the future, matches the search query, and is of type "Other events"
      return (
        eventDate > currentDate &&
        eventName.includes(query) &&
        event.event_type === "Other events"
      );
    });

    // Set the filtered data to the upcoming state
    setUpcoming(filterUpcoming);
  }, [explore, searchQuery]);

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/explore">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer ${
                      pathname.includes("explore")
                        ? "bg-[#CEDDF6]"
                        : "bg-[#F3F4F6]"
                    }`}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <GiTeamIdea />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Conferences
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Competitions">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PiExamBold />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Competitions
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/OtherEvents">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer ${
                      pathname.includes("OtherEvents")
                        ? "bg-[#CEDDF6]"
                        : "bg-[#F3F4F6]"
                    }`}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdOutlineEmojiEvents />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Other Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/add">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <LiaBuysellads />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/SavedEvents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <GiSaveArrow />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/SavedAds">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <FaDownload />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="w-full lg:w-[78%] px-4 pt-2">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center lg:items-end">
                    <div className="flex-grow flex items-center -mt-1">
                      {/* Heading */}
                      <h2 className="text-xl font-semibold text-[#0F3D79] lg:mr-6 mb-4 lg:mb-3">
                        Explore <span className="text-sm"> - Other Events</span>
                      </h2>
                    </div>

                    {/* Search, Filter, and Date Buttons */}
                    <div className="flex flex-col lg:flex-row justify-end items-center w-full lg:w-auto">
                      {/* Search Bar */}
                      <div className="relative w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon className="text-gray-500" />
                        </span>
                        <input
                          type="text"
                          placeholder="Search Company Name"
                          className="py-2 pl-10 pr-4 w-full lg:w-[300px] rounded-xl border border-gray-300"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>

                      {/* Filter Button */}
                      <div className="w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
                        <button
                          className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full lg:w-auto"
                          type="button"
                        >
                          <FilterIcon /> Filter
                        </button>
                      </div>

                      {/* Date Button */}
                      <div className="w-full lg:w-auto">
                        <button
                          className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full lg:w-auto"
                          type="button"
                        >
                          <CalendarTodayIcon /> Date
                        </button>
                      </div>
                    </div>
                  </div>

                  <h2 className="text-sm font-semibold text-[#0F3D79] mb-5 ml-3">
                    Upcoming Other Events
                  </h2>

                  {/* Display Events or No Data Message */}
                  {upcoming.length > 0 ? (
                    <div className="m-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                      {upcoming.map((event, index) => (
                        <div
                          key={index}
                          className="bg-white p-6 rounded-xl shadow-md flex flex-col h-[420px] overflow-hidden"
                        >
                          <NavLink to={`/ViewOtherEvents`}>
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  event?.explore_posted_by_ioi
                                    ?.profile_completion?.logo || ""
                                }
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-cover"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {event?.explore_posted_by_ioi?.name}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {event?.explore_posted_by_ioi?.sub_category}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {event?.event_name}
                            </h4>
                            <p className="text-gray-600 text-sm font-semibold mb-4">
                              {new Date(event?.date).toLocaleDateString(
                                "en-IN",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )}
                            </p>

                            <div className="flex-grow flex justify-center items-center bg-gray-100 rounded-lg overflow-hidden">
                              <img
                                loading="lazy"
                                src={event?.document}
                                alt="Description of the image"
                                className="w-full h-full object-cover"
                              />
                            </div>
                          </NavLink>
                          <div className="flex items-center mt-4 gap-x-4 md:gap-x-[10px]">
                            <RWebShare
                              data={{
                                text: "Check out this event",
                                url: `https://ioi.bharc.in/Publish_view/${event._id}`,
                                title: "Event Share",
                              }}
                              onClick={() =>
                                console.log("Shared successfully!")
                              }
                            >
                              <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                Share
                                <ShareIcon className="ml-2 text-[#346BB7]" />
                              </button>
                            </RWebShare>

                            {user?.explore_saved?.some(
                              (saved) => saved?._id === event?._id
                            ) ? (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                onClick={() => handleSaveTopic(event?._id)}
                              >
                                Saved
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{
                                    fill: "white",
                                  }}
                                />
                              </button>
                            ) : (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                onClick={() => handleSaveTopic(event?._id)}
                              >
                                Save
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{
                                    fill: "#346BB7",
                                  }}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center m-5 h-[360px]">
                      {/* <img
                        src={noData}
                        alt="No Data"
                        height={200}
                        width={200}
                      /> */}
                      <p className="mt-2 text-gray-500">
                        No Upcoming Other Events
                      </p>
                    </div>
                  )}

                  <h2 className="text-sm font-semibold text-[#0F3D79] mb-5 ml-3">
                    All Events{" "}
                  </h2>

                  {/* Topic Cards */}
                  {explore.length > 0 ? (
                    <div className="m-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                      {explore
                        .filter((event) => event.event_type === "Other events") // Filter for 'Competitions' type
                        .map((event, index) => (
                          <div
                            key={index}
                            className="bg-white p-6 rounded-xl shadow-md flex flex-col h-[420px] overflow-hidden"
                          >
                            <NavLink to={`/ViewOtherEvents`}>
                              <div className="flex items-center mb-4">
                                <img
                                  src={
                                    event?.explore_posted_by_ioi
                                      ?.profile_completion?.logo || ""
                                  }
                                  alt="Profile"
                                  className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-cover"
                                />
                                <div>
                                  <h3 className="text-[#0F3D79] text-md font-semibold">
                                    {event?.explore_posted_by_ioi?.name}
                                  </h3>
                                  <p className="text-sm text-gray-500">
                                    {event?.explore_posted_by_ioi?.sub_category}
                                  </p>
                                </div>
                              </div>
                              <h4 className="text-[#0F3D79] font-semibold mb-2">
                                {event?.event_name}
                              </h4>
                              <p className="text-gray-600 text-sm font-semibold mb-4">
                                {new Date(event?.date).toLocaleDateString(
                                  "en-IN",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }
                                )}
                              </p>

                              <div className="flex-grow flex justify-center items-center bg-gray-100 rounded-lg overflow-hidden">
                                <img
                                  loading="lazy"
                                  src={event?.document}
                                  alt="Description of the image"
                                  className="w-full h-full object-cover"
                                />
                              </div>
                            </NavLink>
                            <div className="flex items-center mt-4 gap-x-4 md:gap-x-[10px]">
                              <RWebShare
                                data={{
                                  text: "Check out this event",
                                  url: `https://ioi.bharc.in/Publish_view/${event._id}`,
                                  title: "Event Share",
                                }}
                                onClick={() =>
                                  console.log("Shared successfully!")
                                }
                              >
                                <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                  Share
                                  <ShareIcon className="ml-2 text-[#346BB7]" />
                                </button>
                              </RWebShare>

                              {user?.explore_saved?.some(
                                (saved) => saved?._id === event?._id
                              ) ? (
                                <button
                                  className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                  onClick={() => handleSaveTopic(event?._id)}
                                >
                                  Saved
                                  <BookmarkBorderOutlinedIcon
                                    className="ml-2"
                                    style={{
                                      fill: "white",
                                    }}
                                  />
                                </button>
                              ) : (
                                <button
                                  className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                  onClick={() => handleSaveTopic(event?._id)}
                                >
                                  Save
                                  <BookmarkBorderOutlinedIcon
                                    className="ml-2"
                                    style={{
                                      fill: "#346BB7",
                                    }}
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center m-5 h-[360px]">
                      {/* <img
                        src={noData}
                        alt="No Data"
                        height={200}
                        width={200}
                      /> */}
                      <p className="mt-2 text-gray-500">
                        No Upcoming Conferences
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OtherEvents;
