import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal, TextField } from "@material-ui/core";
import "./Jobs.css";
import WorkIcon from "@mui/icons-material/Work";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneJob, jobsApply, jobsSelector } from "../../api/jobs";
import { makeStyles } from "@mui/styles";
import { Interweave } from "interweave";
import {
  fetchIndividualProfile,
  individualUserSelector,
  saveJob,
} from "../../api/individualUser";
import moment from "moment/moment";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    width: "100%",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
}));

function JobDetails({ id }) {
  const dispatch = useDispatch();
  const { currentjob, loading } = useSelector(jobsSelector);
  const { user, individualtoken } = useSelector(individualUserSelector);
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isApplied, setIsApplied] = useState(false); // Track the application status

  useEffect(() => {
    dispatch(fetchOneJob(id));
    dispatch(fetchIndividualProfile(individualtoken));
  }, [id]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      // Check if the file is a PDF
      if (uploadedFile.type !== "application/pdf") {
        toast.error("Only PDF files are allowed!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
        });
        return; // Don't update the state if it's not a PDF
      }

      setFile(uploadedFile);
      setFileName(uploadedFile.name);
    }
  };

  const handleApplyClick1 = () => {
    if (!file) {
      toast.warning("Please upload your resume or document before applying.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
      });
      return;
    }

    // FormData to handle file submission
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", user._id);
    formData.append("job_id", currentjob._id);

    // Dispatch apply action with file data
    dispatch(jobsApply(formData)).then(() => {
      setIsApplied(true); // Change the button to "Applied"
      toast.success("You have successfully applied!", {
        position: "top-center",
        autoClose: 2000,
      });
      setModalOpen(false);
    });
  };
  const handleApplyClick = () => {
    if (!file) {
      toast.warning("Please upload your resume or document before applying.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
      });
      return;
    }

    // Apply logic here, you can dispatch the apply action with the file
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", user._id);
    formData.append("job_id", currentjob._id);

    // Dispatch apply action with file data
    dispatch(jobsApply(formData)).then(() => {
      setIsApplied(true); // Change the button to "Applied"
      toast.success("You have successfully applied!", {
        position: "top-center",
        autoClose: 2000,
      });
    });

    // Close the modal after applying
    setModalOpen(false);
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <Box>
        <h3 className="mb-2 text-xl font-bold font-heading text-[#0F3D79]">
          {currentjob?.designation}
        </h3>
        <p>
          {currentjob?.job_posted_by?.name} &nbsp;{" "}
          {moment(currentjob?.createdAt).fromNow()}
        </p>
        <br />
        <p>
          <WorkIcon /> &nbsp; {currentjob?.job_type} &nbsp; &nbsp;
          {currentjob?.mode_of_work} &nbsp;
        </p>
        <p>
          <LightbulbIcon /> &nbsp;
          {currentjob?.applicants?.length === 1
            ? `${currentjob?.applicants?.length} applicant for this job`
            : `${currentjob?.applicants?.length} applicants for this job`}
        </p>
        <br />
        <Box>
          {currentjob?.applicants?.length ? (
            <>
              {currentjob?.applicants?.find(
                (item) => item?._id === user?._id
              ) ? (
                <Button
                  variant="contained"
                  color="success"
                  style={{
                    marginRight: "40px",
                    backgroundColor: "green",
                    color: "white",
                  }}
                  // disabled
                >
                  <CheckIcon /> &nbsp; Applied
                </Button>
              ) : (
                !isApplied && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "40px" }}
                    onClick={handleOpenModal}
                  >
                    Apply
                  </Button>
                )
              )}
            </>
          ) : (
            <>
              {!isApplied && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "40px" }}
                  onClick={handleOpenModal}
                >
                  Apply
                </Button>
              )}
            </>
          )}
          {user?.jobs_saved?.find((item) => item?._id === currentjob?._id) ? (
            <Button variant="contained" color="primary">
              Saved
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                dispatch(
                  saveJob(user?._id, { id: currentjob?._id }, individualtoken)
                );
              }}
            >
              Save
            </Button>
          )}
        </Box>

        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            className="modal-content"
            style={{
              padding: 24, // Increased padding for spacious feel
              background: "white",
              maxWidth: 450, // Slightly increased max width
              margin: "auto",
              marginTop: "15vh", // Adjusted to make it less pushed down
              maxHeight: "70vh", // Increased max height for better content spacing
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // More subtle and professional shadow
              borderRadius: "8px", // Rounded corners for modern look
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              id="modal-title"
              gutterBottom
              style={{ fontWeight: 600, color: "#333" }} // Slightly darker text color for clarity
            >
              Upload Your Resume to Apply
            </Typography>

            <TextField
              label="Select your file"
              type="file"
              fullWidth
              onChange={handleFileChange}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                disableUnderline: true, // This removes the underline
              }}
              style={{ marginBottom: 16 }} // Add space below the file input
            />
            
            {fileName && (
              <Typography variant="body2" color="textSecondary" style={{ marginTop: 10 }}>
                {fileName}
              </Typography>
            )}

            <Box
              style={{
                marginTop: 24, // Increased space between buttons
                display: "flex",
                justifyContent: "space-between",
                width: "100%", // Ensure buttons span full width
              }}
            >
              <Button
                onClick={handleCloseModal}
                variant="outlined"
                color="secondary"
                style={{
                  width: "48%", // Make the button width consistent
                  padding: "8px 16px", // Slightly larger buttons for better clickability
                }}
              >
                Cancel
              </Button>

              {file ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "40px" }}
                  onClick={handleApplyClick}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "40px" }}
                  onClick={handleApplyClick}
                  disabled
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        </Modal>

        <h3 className="mt-5 text-xl font-bold font-heading text-[#0F3D79]">
          About job
        </h3>

        <div className="mt-5 text-gray-800">
          <h4 className="mt-5 font-bold font-heading text-[#0F3D79]">
            Job Description
          </h4>
          <div className={classes.boxContainer}>
            <Interweave
              content={currentjob?.job_description}
              customElementAttributes={{
                ul: { className: "custom-ul" },
                li: { className: "custom-li" },
              }}
            />
          </div>
          <h4 className="mt-5 font-bold font-heading text-[#0F3D79]">
            Job Responsibility
          </h4>
          <div className={classes.boxContainer}>
            <Interweave
              content={currentjob?.job_requirement}
              customElementAttributes={{
                ul: { className: "custom-ul" },
                li: { className: "custom-li" },
              }}
            />
          </div>
          <h4 className="mt-5 font-bold font-heading text-[#0F3D79]">
            Job Requirements
          </h4>
          <div className={classes.boxContainer}>
            <Interweave
              content={currentjob?.job_responsibility}
              customElementAttributes={{
                ul: { className: "custom-ul" },
                li: { className: "custom-li" },
              }}
            />
          </div>
        </div>
      </Box>
    </div>
  );
}

export default JobDetails;
