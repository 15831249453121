import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateIndividual } from "../../api/individualUser";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import storage from "../../shared/firebaseStorage";
import { toast } from "react-toastify";
import { nanoid } from "@reduxjs/toolkit";
import EditIcon from "@material-ui/icons/Edit";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Tooltip, Modal, TextField } from "@mui/material";
import { ModalDialog } from "@mui/joy";
import Button from "@mui/joy/Button";
import premiumlog from "../../images/premiumlogo.png";

function ProfilePic({ user, individualtoken }) {
  const dispatch = useDispatch();
  const [Logo, SetLogo] = useState("");
  const [uploadSuccessMsg, setLogoUploadSuccess] = useState("");
  const [logoPercent, setLogoPercent] = useState(0);
  const [open1, setOpen1] = useState(false);
  const [desig, setDesig] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  useEffect(() => {
    setDesig(user?.profile_completion?.designation || "");
    SetLogo(user?.profile_completion?.profile_pic || "");
    setLogoUploadSuccess("");
    setLogoPercent(100);
  }, [user]);

  const handleDesignationChange = (e) => {
    setDesig(e.target.value);
  };

  const handelClose1 = () => {
    SetLogo(user?.profile_completion?.profile_pic);
    setOpen1(false);
  };

  const handleDeleteClickLogo = () => {
    const firebaseStorageURL = Logo;
    const urlParts = firebaseStorageURL.split("?");
    const pathParts = urlParts[0].split("/");
    const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);
    const desertRef = ref(storage, `${fileName}`);
    deleteObject(desertRef)
      .then(() => {
        SetLogo("");
        setLogoUploadSuccess("");
      })
      .catch((error) => {});
    SetLogo("");
    setLogoUploadSuccess("");
  };

  const handleFileInputChange = (event) => {
    setLogoPercent(0);
    const newSelectedFile = event.target.files[0];
    const isJpgOrPng =
      newSelectedFile.type === "image/jpeg" ||
      newSelectedFile.type === "image/png" ||
      newSelectedFile.type === "image/webp";

    if (!isJpgOrPng) {
      toast.warning("You can only upload JPG/PNG/WEBP file!", {
        position: "top-center",
        autoClose: 1000,
      });
      return;
    }

    const isLt100KB = newSelectedFile.size / 1024 < 100;
    if (!isLt100KB) {
      toast.warning("Image size must be less than 100kb", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }

    const storageRef = ref(
      storage,
      `/Individual Profile Completion/${nanoid() + newSelectedFile.name}`
    );
    const uploadFile = uploadBytesResumable(storageRef, newSelectedFile);

    uploadFile.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setLogoPercent(percent);
        if (percent === 100) {
          setLogoUploadSuccess("Profile Uploaded Successfully.");
        }
      },
      (err) => console.error(err),
      () => {
        getDownloadURL(uploadFile.snapshot.ref).then((url) => {
          SetLogo(url);
        });
      }
    );
  };

  const submitProfilePic = (event) => {
    event.preventDefault();
    dispatch(
      updateIndividual(
        user?._id,
        { profile_pic: Logo, designation: desig },
        individualtoken
      )
    );
    handelClose1();
  };

  console.log(user, "profileInds");

  return (
    <div style={{ fontFamily: "Lato" }}>
      <div >
        <div className="relative bg-gray-100 shadow rounded-lg p-2 mb-6 overflow-hidden h-[300px]">
          {(user?.period === 2 || user?.period === 3) && (
            <img
              src={premiumlog} // Replace with the actual URL or path of your top-right image
              alt="icon"
              className="absolute top-0 left-2 w-10 h-10" // Adjust width and height as needed
            />
          )}

          {/* <img
            src={premiumlog} // Replace with the actual URL or path of your top-right image
            alt="icon"
            className="absolute top-0 left-2 w-10 h-10" // Adjust width and height as needed
          /> */}
          <div style={{ marginLeft: "90%" }}>
            <button className="w-full text-md font-semibold text-gray-300 hover:text-gray-500">
              <EditIcon onClick={() => setOpen1(true)} />
            </button>
          </div>
          <img
            className="relative w-32 h-32 mx-auto rounded-full object-cover object-right"
            src={
              Logo ||
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            }
            alt=""
          />
          <div className="mt-6 text-center">
            <p className="text-lg font-semibold text-[#0F3D79]">{user?.name}</p>
            <p className="text-md font-semibold text-gray-500">
              {desig.charAt(0).toUpperCase() + desig.slice(1)}
            </p>
            <p className="text-sm font-semibold text-gray-500 mb-4">
              {user?.unique_id}
            </p>
          </div>
        </div>
      </div>
      <React.Fragment>
        <Modal open={open1}>
          <ModalDialog
            aria-labelledby="nested-modal-title"
            aria-describedby="nested-modal-description"
            sx={(theme) => ({
              [theme.breakpoints.only("xs")]: {
                top: "150px", // Optional: adjust top margin if needed
                left: "10px", // Set left margin to 10px
                right: "10px", // Set right margin to 10px
                bottom: "unset", // Reset bottom to avoid conflicts
                borderRadius: 0,
                transform: "none",
                maxWidth: "unset",
              },
            })}
          >
            <div className="py-5">
              <div className="flex w-full md:w-auto p-2">
                <h2 className="text-[#0F3D79] text-lg font-semibold">
                  Upload Profile Image:
                </h2>
                <Tooltip title="Image should be less than 100KB in size, with a 1:1 ratio of any dimensions, and only .jpg, .png, or .webp formats are allowed.">
                  <InfoOutlinedIcon />
                </Tooltip>
              </div>

              <div className="ml-8">
                <label>
                  <Button
                    component="label"
                    variant="outlined"
                    color="neutral"
                    style={{ marginTop: "3%" }}
                  >
                    {Logo ? (
                      <>
                        <img
                          src={Logo}
                          className="block mx-auto mb-5 w-28 h-28 rounded-full"
                        />
                        <button type="button" onClick={handleDeleteClickLogo}>
                          <HighlightOffIcon className="text-gray-400 hover:text-gray-700" />
                        </button>
                      </>
                    ) : (
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileInputChange}
                      />
                    )}
                  </Button>
                </label>
                {logoPercent < 100 && Logo ? (
                  <span
                    className="ml-5 font-semibold"
                    style={{ color: logoPercent < 100 ? "red" : "green" }}
                  >
                    Upload {logoPercent}%
                  </span>
                ) : (
                  <p className="font-normal" style={{ color: "green" }}>
                    {uploadSuccessMsg}
                  </p>
                )}
              </div>

              <div className="ml-8 mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Designation
                </label>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={desig}
                  onChange={handleDesignationChange}
                  sx={{
                    width: "200px",
                    "& .MuiInputBase-root": {
                      height: "35px",
                      fontSize: "14px",
                      padding: "5px",
                    },
                    "& .MuiFormLabel-root": { fontSize: "12px" },
                  }}
                />
              </div>
            </div>

            <Box sx={{ display: "flex", marginLeft: "30px", gap: 1 }}>
              <Button variant="outlined" color="neutral" onClick={handelClose1}>
                Cancel
              </Button>
              <Button
                variant="solid"
                color="contained"
                style={{ backgroundColor: "#0066A2", color: "white" }}
                onClick={submitProfilePic}
              >
                Submit
              </Button>
            </Box>
          </ModalDialog>
        </Modal>
      </React.Fragment>
    </div>
  );
}

export default ProfilePic;
