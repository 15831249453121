import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@mui/material/Modal";
import { ModalDialog, Textarea } from "@mui/joy";
import { useDispatch } from "react-redux";
import { updateIndividual } from "../../api/individualUser";
import Button from "@mui/joy/Button";
import { Box, FormControl, TextField, Typography } from "@mui/material";

function CarrerObjective({ user, individualtoken }) {
  const dispatch = useDispatch();
  const [open3, setOpen3] = useState(false);
  const [carrerObj, setCarrerObj] = useState("");

  useEffect(() => {
    setCarrerObj(
      user?.profile_completion?.career_objectives
        ? user?.profile_completion?.career_objectives
        : ""
    );
  }, [user]);

  const handelClose3 = () => {
    setOpen3(false);
    setCarrerObj(
      user?.profile_completion?.career_objectives
        ? user?.profile_completion?.career_objectives
        : ""
    );
  };

  const [characterLimit, setCharacterLimit] = useState(
    "Your description cannot exceed 450 characters and above."
  );

  //sumbit carrer objective
  const submitCarreeOnj = (e) => {
    e.preventDefault();
    dispatch(updateIndividual(user?._id, { carrerObj }, individualtoken));
    handelClose3();
  };

  const changeCareer = (e) => {
    const value = e.target.value;
    if (value.length <= 450) setCarrerObj(e.target.value);
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <div className="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
        <button class="w-full flex items-end justify-end mr-2 text-gray-300 hover:text-gray-500">
          <EditIcon onClick={() => setOpen3(true)} />
        </button>
        <div class="w-full  ">
          <h3 class="text-lg ml-4 font-semibold text-[#0F3D79] ">
            Career Objectives
          </h3>
        </div>
        {/* <div className="w-auto px-3">
          <h3 class="text-lg font-semibold text-[#0F3D79]">
            Career Objectives
          </h3>
        </div> */}
        {/* <div className="ml-auto">
          {user?.profile_completion?.career_objectives !== "" ? (
            <button className="w-auto px-2 mb-3 text-gray-300 hover:text-gray-500">
              <EditIcon onClick={() => setOpen3(true)} />
            </button>
          ) : (
            <button className="w-auto px-2 mb-3 text-gray-300 hover:text-gray-500">
              <AddIcon onClick={() => setOpen3(true)} />
            </button>
          )}
        </div> */}
        <div className="w-full">
          <div
            className="h-full px-3 py-3 rounded-xl"
            style={{ marginLeft: "10px" }}
          >
            <div className="mb-8 -mx-4">
              <div className="-mb-3 text-start white-space-no-wrap">
                <div className="text-md font-medium text-gray-500">
                  <p>{user?.profile_completion?.career_objectives}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <Modal open={open3}>
          <ModalDialog
            aria-labelledby="nested-modal-title"
            aria-describedby="nested-modal-description"
            // sx={(theme) => ({
            //   [theme.breakpoints.only("xs")]: {
            //     top: "unset",
            //     bottom: 0,
            //     left: 0,
            //     right: 0,
            //     borderRadius: 0,
            //     transform: "none",
            //     maxWidth: "unset",
            //   },
            // })}
            sx={(theme) => ({
              [theme.breakpoints.only("xs")]: {
                position: "absolute",
                overflowY: "auto",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",

                maxWidth: "100%",
                width: "100%", // Adjust width as per your requirement
                maxHeight: "100%", // Adjust max height as per your requirement
              },
            })}
          >
            <div className="mx-3">
              <h2 class="text-md font-semibold text-[#0F3D79] py-2">
                {" "}
                Career Objectives
              </h2>

              <Textarea
                placeholder="Type in here…"
                value={carrerObj}
                onChange={changeCareer}
                minRows={2}
                maxRows={4}
                endDecorator={
                  <Box
                    sx={{
                      display: "flex",
                      gap: "var(--Textarea-paddingBlock)",
                      pt: "var(--Textarea-paddingBlock)",
                      borderTop: "1px solid",
                      borderColor: "divider",
                      flex: "auto",
                    }}
                  >
                    <Typography
                      level="body-xs"
                      sx={{
                        ml: "auto",
                        color: carrerObj.length < 500 ? "black" : "red",
                      }}
                    >
                      {carrerObj.length < 450
                        ? `${carrerObj.length} character(s)`
                        : characterLimit}
                    </Typography>
                  </Box>
                }
                sx={{
                  minWidth: 450,
                }}
              />

              <div className="flex justify-end  mt-4">
                <Button
                  variant="outlined"
                  color="neutral"
                  onClick={handelClose3}
                >
                  Cancel{" "}
                </Button>
                <Button
                  variant="solid"
                  color="contained"
                  style={{
                    backgroundColor: "#0066A2",
                    color: "white",

                    marginLeft: "10px",
                  }}
                  onClick={submitCarreeOnj}
                >
                  Submit{" "}
                </Button>
              </div>
            </div>
          </ModalDialog>
        </Modal>
      </>
    </div>
  );
}

export default CarrerObjective;
