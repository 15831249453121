import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Logo from "../images/logo.png";
import { IoCloseSharp } from "react-icons/io5";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '10px',
  '@media (max-width: 600px)': {
    marginLeft: '0px',
    // marginRight: '10px',
    width: 'calc(100% - 30px)', 
  },
};

export default function Basicpopup({handlePayment}) {
  const [open, setOpen] = React.useState(true);

  // Prevent closing the modal on outside click
  const handleClose = (event) => {
    event.stopPropagation();
  };

  // Function to close the modal when the close button is clicked
  // const handleCloseButton = () => {
  //   setOpen(false);
  // };
 const handleCloseButton = () => {
   setOpen(false);

   // Set a timeout to reopen the modal after 5 seconds
   setTimeout(() => {
     setOpen(true);
   }, 100000); 
 };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose} // Prevent closing the modal on outside click
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          // Disable backdrop click to close the modal
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Box sx={style}>
          {/* <IconButton
            aria-label="close"
            onClick={handleCloseButton}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              zIndex: 1, // Ensure it stays above other content
            }}
          >
            <CloseIcon />
          </IconButton> */}
          <div className="w-full flex justify-end items-center relative group">
            <IoCloseSharp
              onClick={handleCloseButton}
              className="text-red-900 text-2xl hover:text-red-500"
            />
            {/* <span className="absolute top-8 ml-2 text-red-900 opacity-0 group-hover:opacity-100 transition-opacity duration-300"> */}
              {/* Close */}
            {/* </span> */}
          </div>

          {/* <div> */}
          <img
            src={Logo}
            alt="Logo"
            style={{
              width: "100px",
              marginBottom: "30px",
              marginTop: "20px",
            }}
          />
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h3"
            sx={{ color: "#0F3D79", fontWeight: 600 }}
          >
            Free period completed
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center" }}
          >
            Our free trial period has ended. To continue using our platform,
            please subscribe to our platform. Thank you for trying out our
            services!
          </Typography>
          <Button
            // className="p-4 mb-6"
            variant="contained"
            sx={{
              mt: 2,
              mb: 4,
              textTransform: "none", // Prevents uppercase transformation
              backgroundColor: "#0F3D79", // Change this to your desired color
              "&:hover": {
                backgroundColor: "#0D3366", // Darker shade for hover effect
              },
            }}
            onClick={() => {
              handlePayment();
            }}
          >
            Subscribe Now
          </Button>
          {/* </div> */}
        </Box>
      </Modal>
    </div>
  );
}
