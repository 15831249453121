import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { individualUserSelector } from "../../api/individualUser";
import { getOneAdd, exploreSelector } from "../../api/explore";
import ShareIcon from "@mui/icons-material/Share";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import image2 from "../../images/logo.png"; // Placeholder image
import { RWebShare } from "react-web-share";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import ExploreIcon from "@mui/icons-material/Explore";

function ViewAds() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams(); // Get the ad ID from the URL
  const { user, individualtoken } = useSelector(individualUserSelector);
  const { currentAdd } = useSelector(exploreSelector); // Select the current ad from the Redux store
  const [isSaved, setIsSaved] = useState(false); // Manage the save state for the ad
  const [zoomImage, setZoomImage] = useState(null); // State for zoomed image

  useEffect(() => {
    if (id) {
      dispatch(getOneAdd(id)); // Fetch the ad details based on the ID from the URL
    }
  }, [id, individualtoken, dispatch]);

  const handleClick = () => {
    setIsSaved((prevState) => !prevState); // Toggle the "Saved" state
  };

  // Function to handle image click and open modal
  const handleImageClick = (image) => {
    setZoomImage(image); // Set the clicked image for zoom view
  };

  // Function to close the zoom modal
  const closeZoom = () => {
    setZoomImage(null);
  };

  // Function to render media (images or video) based on the file format
  const renderAdMedia = (documents) => {
    if (!documents || documents.length === 0) {
      return <p>No media available</p>; // Fallback when no documents are present
    }

    return documents.map((document, index) => {
      const isVideo = document.includes(".mp4");
      const isImage = [".jpg", ".jpeg", ".png", ".webp"].some((ext) =>
        document.includes(ext)
      );

      if (isVideo) {
        return (
          <video
            key={index}
            controls
            className="w-full h-[200px] object-cover rounded-lg my-2"
          >
            <source src={document} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      }

      if (isImage) {
        return (
          <img
            key={index}
            src={document}
            alt={`Ad media ${index}`}
            className="w-full h-[200px] object-cover rounded-lg my-2 cursor-pointer"
            onClick={() => handleImageClick(document)} // Add click handler for zoom
          />
        );
      }

      return <p key={index}>Unsupported format</p>; // Fallback for unsupported formats
    });
  };

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            {/* Sidebar */}
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              {/* Sidebar Links */}
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/explore">
                  <div
                    className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("explore")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Conferences 
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Competitions">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Competitions
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/OtherEvents">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        OtherEvents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/add">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/SavedEvents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/SavedAds">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            {/* Main Content */}
            <div className="w-full lg:w-[78%] px-4 pt-2">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4 flex flex-col sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="text-lg font-semibold text-[#0F3D79]">
                      View Ad Details
                    </h2>
                  </div>

                  {/* Ad Content */}
                  <div className="flex flex-col lg:flex-row justify-between items-start">
                    {/* Left Content */}
                    <div className="w-full lg:w-1/2 bg-white mb-4 lg:mb-0 h-[500px]">
                      <div className="border p-4 rounded-md h-[500px]">
                        <div className="flex items-center mb-4">
                          <img
                            src={
                              currentAdd?.add_posted_by_ioi?.profile_completion
                                ?.logo || image2
                            }
                            alt="Profile"
                            className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                          />
                          <div>
                            <h3 className="text-[#0F3D79] text-md font-semibold">
                              {currentAdd?.add_posted_by_ioi?.name}
                            </h3>
                            <p className="text-sm text-gray-500">
                              {currentAdd?.add_posted_by_ioi?.sub_category}
                            </p>
                          </div>
                        </div>

                        <h4 className="mt-4 text-[#0F3D79] text-md font-semibold">
                          {currentAdd?.add_title}
                        </h4>
                        <p className="mt-2 text-gray-700">
                          {currentAdd?.description}
                        </p>
                        
                        <div className="flex items-center mt-4 gap-x-4">
                          <RWebShare
                            data={{
                              text: "Check out this Ad",
                              url: `http://localhost:3000/view_adds/${currentAdd?._id}`,
                              title: "Ad Share",
                            }}
                          >
                            <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                              Share
                              <ShareIcon className="ml-2 text-[#346BB7]" />
                            </button>
                          </RWebShare>
                          <button
                            className={`flex items-center justify-between px-4 py-2 ${
                              isSaved
                                ? "bg-[#002357] text-white"
                                : "bg-white text-gray-600"
                            } border border-gray-300 rounded-lg text-sm font-semibold`}
                            onClick={handleClick}
                          >
                            {isSaved ? "Saved" : "Save"}
                            <BookmarkBorderOutlinedIcon
                              className="ml-2"
                              style={{
                                fill: isSaved ? "white" : "#346BB7",
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Right Content - Images/Video Display */}
                    <div className="w-full lg:w-1/2 flex flex-col justify-start ml-2 gap-4">
                      <div className="border border-gray-300 p-2 rounded-lg h-[500px]">
                        {renderAdMedia(currentAdd?.document)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Image Zoom Modal */}
      {zoomImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeZoom}
        >
          <div className="relative">
            <img
              src={zoomImage}
              alt="Zoomed view"
              className="max-w-full max-h-screen rounded-lg"
              onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking on the image
            />
            <button
              className="absolute top-2 right-2 text-white text-2xl font-bold"
              onClick={closeZoom}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewAds;
