import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  individualUserSelector,
  usersRequestReceived,
  declineConnectionRequest,
  acceptConnectionRequest,
} from "../../api/individualUser";
import { InputAdornment, TextField, FormControl } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Autocomplete } from "@mui/joy";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router";
import {
  category,
  Subcategory,
  Subcategory2,
  Subcategory3,
  Subcategory4,
} from "../../shared/constants";

function RequestReceived() {
  const {
    individualtoken,
    user,
    request_received = [],
  } = useSelector(individualUserSelector); // Defaulting request_received to an empty array if undefined
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]); // Initialize data to hold the users
  const [debouncedText] = useDebounce(search, 1000);

  const [studentCategory, setCategory] = useState({
    category: "",
  });

  // Fetch request received data only when user is defined
  useEffect(() => {
    if (user?._id) {
      dispatch(usersRequestReceived(user._id));
    }
  }, [user, dispatch]);

  useEffect(() => {
    let filteredData = request_received;

    if (debouncedText) {
      // Filter based on search term (name or company)
      filteredData = filteredData.filter((item) =>
        item.name.toLowerCase().includes(debouncedText.toLowerCase())
      );
    }

    if (studentCategory.category) {
      // Further filter based on category
      filteredData = filteredData.filter(
        (item) =>
          item?.profile_completion?.category === studentCategory.category
      );
    }

    setData(filteredData);
  }, [debouncedText, studentCategory.category, request_received]);

  const acptHandel = (acceptorId, recieverId) => {
    dispatch(
      acceptConnectionRequest({ acceptorId, recieverId }, individualtoken)
    );
  };

  const declineHandel = (userId, declinedId) => {
    dispatch(declineConnectionRequest({ userId, declinedId }, individualtoken));
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <section className="py-5">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -m-4">
            {data.length > 0 ? (
              data.map((item, i) => {
                if (item?.profile_activate_status)
                  return (
                    <div
                      key={i}
                      className="w-full lg:w-1/4 p-2 cursor-pointer"
                      onClick={() => nav(`/profile/${item?._id}`)}
                    >
                      <div className="relative p-2 bg-white shadow rounded-xl">
                        <img
                          className="w-32 h-32 p-1 mb-2 mx-auto rounded-full border border-indigo-50"
                          // src={
                          //   item?.profile_completion?.profile_pic
                          //     ? item?.profile_completion?.profile_pic
                          //     : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          // }
                          alt=""
                        />
                        <h3 className="text-center font-semibold text-xl text-[#0F3D79] whitespace-nowrap overflow-hidden text-ellipsis">
                          {item?.name}
                        </h3>
                        {/* <p className="mb-5 text-center text-md text-gray-500 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                          {item?.email}
                        </p> */}
                        <div className="flex gap-2 justify-center">
                          <div className="text-center">
                            <a
                              className="relative group inline-block py-2 px-2 border font-semibold text-blue-900 hover:text-white rounded-full bg-white hover:hover:bg-[#0F3D79] transition duration-300 overflow-hidden "
                              onClick={(e) => {
                                e.stopPropagation();
                                acptHandel(user?._id, item._id);
                              }}
                            >
                              <span className="relative px-3 text-md">
                                Accept
                              </span>
                            </a>
                          </div>
                          <div className="text-center">
                            <a
                              className="relative group inline-block border py-2 px-3 font-semibold text-blue-900 hover:text-white rounded-full bg-white hover:hover:bg-[#0F3D79] transition duration-300 overflow-hidden "
                              onClick={(e) => {
                                e.stopPropagation();
                                declineHandel(user?._id, item._id);
                              }}
                            >
                              <span className="relative px-3 text-md">
                                Decline
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
              })
            ) : (
              <p className="ml-4 text-md text-gray-500">No requests received</p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default RequestReceived;
