import React from "react";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@mui/material/Modal";
import { ModalDialog } from "@mui/joy";
import { useDispatch } from "react-redux";
import { updateIndividual } from "../../api/individualUser";
import Button from "@mui/joy/Button";
import { Autocomplete,Box,Checkbox  } from '@mui/material';
import {  Keyareas  } from "../../shared/constants";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { toast } from "react-toastify";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1000px",
  height: 300,
  bgcolor: "background.paper",
  border: "px solid #000",
  boxShadow: 2,
  p: 2,
};

function Skills({ user, individualtoken }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedkeyArea, setSelectedkeyArea] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    preFill();
  }, [user]);

  //Autocomplete useEffect
  React.useEffect(() => {
    const keyInterst = selectedkeyArea?.map((item) => {
      return item?.title;
    });
    setSkills(keyInterst);
  }, [selectedkeyArea]);

  const preFill = () => {
    setSkills(user?.profile_completion?.skills);
    user?.profile_completion?.skills?.length != 0
      ? setSelectedkeyArea(
          user?.profile_completion?.skills?.map((item) => {
            return { title: item };
          })
        )
      : setSelectedkeyArea([]);
    user?.profile_completion?.skills?.length != 0
      ? setTimeout(() => {
          setVisible(true);
        }, [1000])
      : setVisible(true);
  };

  const handelClose = () => {
    setOpen(false);
    preFill();
  };

  //Autocomplete function
  const handleKeyAreaSelection = (value) => {
    if (value.length <= 4) {
      setSelectedkeyArea([...value]);
    }
    if (value.length > 4)
      toast.warning("You can only select 4 domains", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  };

  //submit function
  const submitSkills = (e) => {
    if (skills.length == 0) {
      toast.warning("Please select atleast one Interested Domains", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeButton: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      e.preventDefault();
      dispatch(updateIndividual(user?._id, { skills }, individualtoken));
      handelClose();
    }
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <div class="p-1 mb-2 py-1 bg-gray-100 rounded-xl  h-[300px]">
        <div class="flex flex-wrap   justify-between items-center">
          {/* <button class="w-full flex items-end justify-end mr-2 text-gray-300 hover:text-gray-500">
            <EditIcon onClick={() => setOpen(true)} />
          </button>
          <div class="w-full  ">
            <h3 class="text-lg ml-2 font-semibold text-[#0F3D79] ">
              Interested Domains
            </h3>
          </div> */}
          <div class="w-full flex justify-between items-end">
            <h3 class="text-lg ml-1 font-semibold text-[#0F3D79]">
              Interested Domains
            </h3>

            <button
              class="text-gray-300  hover:text-gray-500"
              onClick={() => setOpen(true)}
            >
              <EditIcon />
            </button>
          </div>
          {/* <div class="w-auto px-4">
            <h3 class="text-lg font-semibold text-[#0F3D79] mb-3">
              Interested Domains
            </h3>
          </div> */}
          {/* {user?.profile_completion?.skill?.length == 0 ? (
            <button class="w-auto px-2 mb-3 text-gray-500">
              <AddIcon onClick={() => setOpen(true)} />
            </button>
          ) : (
            <button class="w-auto px-2 mb-3 text-gray-300 hover:text-gray-500">
              <EditIcon onClick={() => setOpen(true)} />
            </button>
          )} */}
          <div class="w-full">
            <div class="h-full py-7 px-4 rounded-xl">
              <div class="mb-8 -mx-4">
                <div class="-mb-3 text-start white-space-no-wrap">
                  {user?.profile_completion?.skills.map((item, i) => {
                    return (
                      i < 6 && (
                        <p class="px-2 pl-5 py-1 my-1 mb-1 text-gray-500 bg-gray-200 text-sm font-medium leading-6 rounded-full">
                          {item}
                        </p>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          <Modal open={open}>
            <Box style={style}>
              <ModalDialog
                aria-labelledby="nested-modal-title"
                aria-describedby="nested-modal-description"
                /* sx={(theme) => ({
                  [theme.breakpoints.only("xs")]: {
                    top: "unset",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    borderRadius: 0,
                    transform: "none",
                    maxWidth: "unset",
                  },
                })} */
              >
                <div class="w-full md:w-auto">
                  <h2 class=" text-[#0F3D79] text-lg font-semibold mb-1">
                    Interested Domains
                  </h2>
                </div>
                <div>
                  {visible ? (
                    <Autocomplete
                      multiple
                      // style={{height:'20px'}}
                      id="checkboxes-tags-demo"
                      options={Keyareas}
                      disableCloseOnSelect
                      name={(option) => option?.title}
                      getOptionLabel={(option) => option?.title}
                      renderOption={(props, option, { selected }) => {
                        const isOptionSelected = (selectedkeyArea || []).some(
                          (defaultOption) =>
                            defaultOption.title === option.title
                        );
                        return (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 2 }}
                              checked={selected || isOptionSelected}
                            />
                            {option.title}
                          </li>
                        );
                      }}
                      value={selectedkeyArea}
                      onChange={(event, newValue) =>
                        handleKeyAreaSelection(newValue)
                      }
                      style={{ width: "100%", marginTop: "10px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Interested Domains"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex justify-end  mt-4">
                  <Button
                    variant="outlined"
                    color="neutral"
                    onClick={handelClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="solid"
                    color="contained"
                    style={{
                      backgroundColor: "#0066A2",
                      color: "white",

                      marginLeft: "10px",
                    }}
                    onClick={submitSkills}
                  >
                    Submit{" "}
                  </Button>
                </div>
              </ModalDialog>
            </Box>
          </Modal>
        </>
      </div>
    </div>
  );
}

export default Skills;
