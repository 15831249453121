import React, { useEffect, useState } from "react";
import "./Topics.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import DvrIcon from "@mui/icons-material/Dvr";
import { MdOutlineTitle } from "react-icons/md";
import { BsFillBookmarkPlusFill } from "react-icons/bs";
import { individualUserSelector } from "../../api/individualUser";
import { NavLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Addtopics from "./Addtopics";
import Image1 from "../../images/comingsoon.png";
import FilterIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WorkIcon from "@mui/icons-material/Work";
import { createTopics } from "../../api/topic";
import { toast } from "react-toastify";
import { Keyareas } from "../../shared/constants"; // Assuming this is the correct import
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { nanoid } from "@reduxjs/toolkit";
import storage from "../../shared/firebaseStorage";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const useStyles = makeStyles(() => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
}));

function CreateTopics() {
  const { individualtoken, user } = useSelector(individualUserSelector);
  const [document, setDocument] = useState("");
  const [selectedKeyArea, setSelectedKeyArea] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [pdfPercent, setPdfPercent] = useState(0);
  const [uploadSuccessMsg, setUploadSuccessMsg] = React.useState("");

  const [topic, setTopic] = useState({
    name: "",
    summary: "",
    topic_status: "pending",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { pathname } = useLocation();

  useEffect(() => {
    const keyInterest = selectedKeyArea.map((item) => item.title);
    setSkills(keyInterest);
  }, [selectedKeyArea]);

  const handleFileInputChange = (event) => {
    const newSelectedFile = event.target.files[0];

    setSelectedFile(newSelectedFile);

    if (newSelectedFile) {
      const isPdf = newSelectedFile.type === "application/pdf";
      if (!isPdf) {
        toast.warning("You can only upload PDF files!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return; // Exit if the file is not a PDF
      }

      const isLt100KB = newSelectedFile.size / 1024 < 100;
      if (!isLt100KB) {
        toast.warning("File size must be less than 100KB", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return; // Exit if the file size exceeds 100KB
      }

      const storageRef = ref(
        storage,
        `/Individual Profile Completion/${nanoid() + newSelectedFile.name}`
      );
      const uploadFile = uploadBytesResumable(storageRef, newSelectedFile);

      uploadFile.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPdfPercent(percent);
          if (percent === 100) {
            setUploadSuccessMsg("File uploaded successfully.");
          }
        },
        (err) => {
          console.error(err);
        },
        () => {
          getDownloadURL(uploadFile.snapshot.ref).then((url) => {
            setDocument(url);
          });
        }
      );
    }
  };

  const handleChange = (e) => {
    setTopic({ ...topic, [e.target.name]: e.target.value });
   
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const { name, summary } = topic;

    if (name && summary) {
      const topicInfo = {
        ...topic,
        skills: skills, // Include skills in the payload
        document: document, // Include document URL if uploaded
        topic_posted_by: user._id,
      };

      try {
        // Dispatch action to create topic and wait for it to complete
        await dispatch(createTopics(topicInfo));

        // Show success toast
        const toastId = toast.warn(
          "Topic submitted. Awaiting approval before publication.",
          {
            position: "top-center",
            autoClose: false, // Prevent automatic closing
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );

        // Wait for a specified time before navigating
        setTimeout(() => {
          toast.dismiss(toastId); // Manually dismiss the toast after the delay
          navigate(-1); // Navigate back to the previous page
        }, 3000); // Adjust delay as needed (e.g., 3000ms = 3 seconds)
      } catch (error) {
        // Handle any errors that occurred during topic creation
        console.error("Error creating topic:", error);
        toast.error("Failed to create topic. Please try again later.", {
          position: "top-center",
          autoClose: 3000, // Display error toast for a fixed time
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.warning("Please fill all the fields", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleKeyAreaSelection = (value) => {
    if (value.length <= 4) {
      setSelectedKeyArea([...value]);
    } else {
      toast.warning("You can only select up to 4 domains", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/topics">
                  <div
                    className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("topics")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdOutlineTitle />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        All Topics
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Mytopics">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <DvrIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Topics
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/savedTopics">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <BsFillBookmarkPlusFill />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Topics
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="w-full lg:w-[78%] px-4 pt-2">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  <div className="my-4 mx-0">
                    <h2 className="text-lg font-semibold text-[#0F3D79]">
                      Create Topic
                    </h2>
                  </div>

                  <form onSubmit={submitHandler}>
                    <div className="mb-6">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="domain"
                      >
                        Domain of the Topic
                      </label>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={Keyareas}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              style={{ marginRight: 2 }}
                              checked={selected}
                            />
                            {option.title}
                          </li>
                        )}
                        value={selectedKeyArea}
                        onChange={(event, newValue) =>
                          handleKeyAreaSelection(newValue)
                        }
                        style={{ width: "100%", marginTop: "10px" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Domains"
                            placeholder="Select up to 4 domains"
                            required={skills.length === 0}
                          />
                        )}
                      />
                    </div>

                    <div className="mb-6">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="name"
                      >
                        Topic Name
                      </label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={topic.name}
                        onChange={handleChange}
                        placeholder="Enter your topic name"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="mb-6">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="summary"
                      >
                        Topic Summary
                      </label>
                      <textarea
                        id="summary"
                        name="summary"
                        value={topic.summary}
                        onChange={handleChange}
                        placeholder="Enter your topic summary"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      ></textarea>
                    </div>

                    <div className="mb-6">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="upload"
                      >
                        Upload a Document
                      </label>
                      <div className="relative flex items-center border-dashed border-2 border-gray-300 rounded p-4">
                        <input
                          id="upload"
                          type="file"
                          accept="application/pdf"
                          onChange={handleFileInputChange}
                          className="absolute inset-0 opacity-0 cursor-pointer"
                        />
                        <div className="flex items-center justify-center w-full h-full text-gray-500">
                          <PictureAsPdfIcon style={{ fontSize: 50 }} />
                          <span className="ml-4 text-lg">
                            Drag & Drop or Browse
                          </span>
                        </div>
                      </div>
                      {pdfPercent > 0 && (
                        <div className="mt-2">
                          <progress value={pdfPercent} max="100" />
                          <span>{pdfPercent}%</span>
                        </div>
                      )}
                      {uploadSuccessMsg && (
                        <div className="mt-2 text-green-600">
                          {uploadSuccessMsg}
                        </div>
                      )}
                    </div>

                    <div className="mb-6">
                      <button
                        type="submit"
                        className="bg-[#0F3D79] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Create Topic
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CreateTopics;
