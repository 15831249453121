import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/joy/Button";
import Stack from "@mui/material/Stack";
import Logo from "../../images/logo.png";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/joy/Modal";
import FormLabel from "@mui/joy/FormLabel";
import { Input } from "@material-ui/core";
import ModalDialog from "@mui/joy/ModalDialog";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  createverificationemail,
  verificationemailSelector,
  updateverificationemail,
} from "../../api/verifyemail";
import { createIndividualUser } from "../../api/individualUser";
import Image from "../../images/bharc login-06.png";
import storage from "../../shared/firebaseStorage";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/joy";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { keyUri } from "../../key";
import { useDebounce } from "use-debounce";
import axios from "axios";
import { FormHelperText } from "@mui/material";
import { toast } from "react-toastify";
import { useMediaQuery } from "@material-ui/core";
import { nanoid } from "@reduxjs/toolkit";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  halfLg: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: theme.breakpoints.values["lg"] / 2,
    },
  },
  firstBox: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up("lg")]: {
      marginLeft: "auto",
      paddingTop: theme.spacing(25),
      paddingBottom: theme.spacing(0),
      paddingRight: theme.spacing(22),
    },
  },
  fullHeightImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: "contain",
    height: "100vh",
    width: "100%",
  },
}));
const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const initialFormData = {
  email: "",
  type: "individual",
  firstName: "",
  lastName: "",
  password: "",
  confirmPassword: "",
  freePeriodEndDate: "",
  document: "",
};

export default function SignUpIndividual() {
  const dispatch = useDispatch();
  const { validationdetails } = useSelector(verificationemailSelector);
  const navigation = useNavigate();
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const [rules, setRules] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [buttondisable, setButtonDisable] = useState(true);

  const [formData, setFormData] = useState(initialFormData);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const [otp, setOtp] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [valMsg, setValMsg] = useState(null);
  const [allow, setAllow] = useState(true);

  //Autocomplete useStates
  const [selectedFile, setSelectedFile] = useState(null);
  const [percent, setPercent] = useState(0);
  const [uploadSuccessMsg, setUploadSuccess] = useState("Upload valid Govt.ID");
  const [document, setDocument] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");

  const [search, setSearch] = useState("");
  const [debouncedText] = useDebounce(search, 2000);
  const [flag, setFlag] = useState();
  const [count, setCount] = useState(1);
  const [buttonName, setButtonName] = useState("Validate");

  const classes = useStyles();

  const content = {
    brand: { image: { Logo }, width: 100 },
    "02_header": "Sign Up",
    "02_primary-action": "Sign Up",
    "02_secondary-action": "Don't have an account?Sign up",
    "02_tertiary-action": "Forgot password?",
  };

  let brand;

  if (content.brand.image.Logo) {
    brand = (
      <img src={content.brand.image.Logo} alt="" width={content.brand.width} />
    );
  } else {
    brand = content.brand.text || "";
  }

  // ----------------------firebase file upload---------------------

  const handleFileInputChange = (event) => {
    const newSelectedFile = event.target.files[0];
    setSelectedFile(newSelectedFile);

    if (newSelectedFile) {
      const isJpgOrPng =
        newSelectedFile.type === "image/jpeg" ||
        newSelectedFile.type === "image/png" ||
        newSelectedFile.type === "image/webp";
      if (!isJpgOrPng) {
        toast.warning("You can only upload JPG/PNG/WEBP file!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      const isLt100KB = newSelectedFile.size / 1024 < 100;
      if (!isLt100KB) {
        toast.warning("Image size must be less than 100kb", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (isJpgOrPng && isLt100KB) {
        const storageRef = ref(
          storage,
          `/Individual Profile Registration/${nanoid() + newSelectedFile.name}`
        );
        const uploadFile = uploadBytesResumable(storageRef, newSelectedFile);

        uploadFile.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // update progress
            setPercent(percent);
            if (percent === 100)
              setUploadSuccess("Document Uploaded Successfully.");
          },
          (
              err // //console.log(err),
            ) =>
            () => {
              // download url
              getDownloadURL(uploadFile.snapshot.ref).then((url) => {
                setDocument(url);
              });
            }
        );
      }
    }
  };
  // -------------------------------------------------------

  const handleDeleteClick = () => {
    if (document) {
      const firebaseStorageURL = document;
      const urlParts = firebaseStorageURL.split("?");
      const pathParts = urlParts[0].split("/");
      const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);
      const desertRef = ref(storage, `${fileName}`);
      if (desertRef) {
        deleteObject(desertRef)
          .then(() => {
            // File deleted successfully
            setSelectedFile(null);
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            // //console.log(error)
          });
      }
    }

    setSelectedFile(null);
    setPercent(0);

    setUploadSuccess("");
  };
  const isPasswordValid = (password) => {
    const minLength = 8;
    const uppercaseRegex = /[A-Z]/;
    const numbercaseRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    const regex = /\s/;

    return (
      password.length >= minLength &&
      uppercaseRegex.test(password) &&
      specialCharRegex.test(password) &&
      numbercaseRegex.test(password) &&
      !regex.test(password)
    );
  };

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;

    if (name === "password") {
      const result = isPasswordValid(value);
      !result
        ? setRules(
            "Password must be atleast 8 characters long. Use minimum 1 special charcter, 1 number and 1 uppercase letter without any spaces"
          )
        : setRules("");
    }

    if (name === "password" && password.confirmPassword !== "") {
      setPassword({ confirmPassword: "" });
      setPasswordMismatch(false);
    }
    if (name == "confirmPassword") {
      if (password.password !== value) {
        setPasswordMismatch(true);
      } else setPasswordMismatch(false);
    }
    setPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") setEmail(value);
    // else setEmailError("");

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const checkEmailAvailability = () => {
    setEmailError("");
    // Make an AJAX request to your server
    axios
      .get(keyUri.BACKEND_URI + `/users/check-duplicate-IND-email/${email}`)
      .then(({ data }) => setFlag(data.message))
      .catch((error) => {
        setFlag(error.response.data.message);
        setEmailError(error.response.data.message);
      });
      
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const dataSent = {
      email: formData.email.toLowerCase(),
      type: "individual",
      name:
        formData.firstName.charAt(0).toUpperCase() +
        formData.firstName.slice(1) +
        " " +
        formData.lastName.charAt(0).toUpperCase() +
        formData.lastName.slice(1),
      password: password.password,
      document: document,
      freePeriodEndDate: formData.freePeriodEndDate,
      validationId: validationdetails._id,
    };
    dispatch(createIndividualUser(dataSent));
    setOpen(false);
    navigation("/");
  };

  const showModal = () => {
    setOpen(true);
  };
  const onFinish = (event) => {
    event.preventDefault();

    // setButtonDisable(false);

    if (
      formData.email === "" ||
      formData.firstName == "" ||
      password.password == "" ||
      password.confirmPassword == "" ||
      passwordMismatch ||
      rules != "" ||
      emailError != ""
    ) {
      // setButtonDisable(true);

      toast.error("Please fill all the details.", {
        position: "top-center",
        // autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      showModal();
      dispatch(createverificationemail(formData));
    }
  };

  // console.log("OTP is",otp)

  const verifyOtp = (e) => {
    setValMsg("");
    if (e.target.value?.length >= 4) {
      if (e.target.value == otp) {
        setValMsg("*OTP verified");
        setCount(1);
        setAllow(false);
      } else {
        setValMsg("*OTP invalid");
        setCount(count + 1);
        setAllow(true);
      }
    }
  };
  
  const resendOtp = (e) => {
    setCount(1);
    setValMsg("");
    setAllow(true);
    dispatch(updateverificationemail(validationdetails._id, {}));
  };


  const isMobile = useMediaQuery("(max-width: 1279px)"); // Adjust the width as needed

  useEffect(() => {
    setOtp(validationdetails.otp);
  }, [validationdetails]);

  return (
    <div>
      <section style={{ fontFamily: "Lato" }}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Box position="relative" height="100%">
              {isMobile ? null : ( // Render nothing for mobile
                <img className={classes.fullHeightImage} src={Image} alt="" />
              )}
            </Box>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Box className={[classes.halfLg, classes.firstBox]}>
              <Container maxWidth="xs">
                <form onSubmit={onFinish}>
                  <Box>
                    <Box mb={0} textAlign="center">
                      <div style={{ marginLeft: "30%", marginBottom: "5%" }}>
                        <Link
                          href="#"
                          variant="h4"
                          color="inherit"
                          underline="none"
                        >
                          {brand}
                        </Link>
                      </div>
                    </Box>

                    <div>
                      <div>
                        <FormControl
                          sx={{ width: "100%", marginTop: "10px" }}
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                        >
                          <InputLabel>First Name</InputLabel>
                          <OutlinedInput
                            type="text"
                            required
                            label="firstName"
                            name="firstName" // Make sure to set the name attribute
                            value={formData.firstName}
                            onChange={handleFormChange}
                            // You can add more attributes or props as needed
                          />
                        </FormControl>
                      </div>

                      <div>
                        <FormControl
                          sx={{ width: "100%", marginTop: "10px" }}
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                        >
                          <InputLabel>Last Name</InputLabel>
                          <OutlinedInput
                            type="text"
                            required
                            label="lastName"
                            name="lastName" // Make sure to set the name attribute
                            value={formData.lastName}
                            onChange={handleFormChange}
                            // You can add more attributes or props as needed
                          />
                        </FormControl>
                      </div>

                      <div>
                        <FormControl
                          sx={{ width: "100%", marginTop: "10px" }}
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                        >
                          <InputLabel>Email</InputLabel>
                          <OutlinedInput
                            type="email"
                            required
                            label="Email"
                            placeholder="Please provide a valid email address"
                            name="email" // Make sure to set the name attribute
                            value={formData.email}
                            onChange={handleFormChange}
                            onBlur={checkEmailAvailability}
                            error={Boolean(emailError) || Boolean(flag)}
                            helperText={
                              emailError ? emailError : flag ? flag : ""
                            }
                            // You can add more attributes or props as needed
                          />
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {emailError ? emailError : flag ? flag : ""}
                          </p>
                        </FormControl>
                      </div>

                      <FormControl
                        fullWidth
                        sx={{ width: "100%", marginTop: "10px" }}
                        size="small"
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          aria-describedby="outlined-password-helper-text"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          name="password"
                          onChange={handlePasswordChange}
                          required
                          error={Boolean(rules != "")}
                          // value={password.password}
                        />
                        <FormHelperText
                          id="outlined-password-helper-text"
                          style={{ color: "red" }}
                        >
                          {rules}
                        </FormHelperText>
                      </FormControl>

                      <FormControl
                        fullWidth
                        sx={{ width: "100%", marginTop: "10px" }}
                        size="small"
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-adornment-confirm-password">
                          Confirm Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-confirm-password"
                          type={showConfirmPassword ? "text" : "password"}
                          aria-describedby="outlined-password-helper-text"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Confirm Password"
                          name="confirmPassword"
                          onChange={handlePasswordChange}
                          value={password?.confirmPassword}
                          error={Boolean(passwordMismatch)}
                          required
                          // value={password.password}
                        />
                        <FormHelperText
                          id="outlined-confirm-password-helper-text"
                          style={{ color: "red" }}
                        >
                          {passwordMismatch ? "Password doesn not match" : ""}
                        </FormHelperText>
                      </FormControl>

                      <div className="flex flex-wrap">
                        <label>
                          <Button
                            style={{ marginTop: "3%" }}
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            variant="outlined"
                            color="neutral"
                          >
                            {selectedFile ? (
                              <>
                                {selectedFile.name}
                                <button
                                  type="button"
                                  onClick={handleDeleteClick}
                                >
                                  <DeleteIcon />
                                </button>
                              </>
                            ) : (
                              <p>
                                <CloudUploadOutlinedIcon /> Upload a file
                              </p>
                            )}
                            <VisuallyHiddenInput
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={handleFileInputChange}
                            />
                          </Button>
                          <>
                            {percent < 100 && selectedFile ? (
                              <span
                                className="ml-5 font-semibold"
                                style={{
                                  color: percent < 100 ? "red" : "green",
                                }}
                              >
                                Upload {percent}%
                              </span>
                            ) : (
                              <span
                                className="ml-5 font-semibold"
                                style={{ color: "green" }}
                              >
                                {uploadSuccessMsg}
                              </span>
                            )}
                          </>
                        </label>
                      </div>
                      {/* <p className="text-gray-700 font-medium text-sm">
                        Please upload a valid Government id for verification.
                      </p> */}
                    </div>
                    <Button
                      sx={{ marginTop: "15px", backgroundColor: "#0066A2" }}
                      type="submit"
                      // style={{
                      //   backgroundColor: buttondisable ? "gray" : "green",
                      // }}
                    >
                      Submit
                    </Button>
                  </Box>
                </form>
              </Container>
            </Box>
          </Grid>
        </Grid>
        <>
          <Modal open={open}>
            <ModalDialog
              aria-labelledby="basic-modal-dialog-title"
              aria-describedby="basic-modal-dialog-description"
              sx={{ maxWidth: 500 }}
            >
              <Typography id="basic-modal-dialog-title" level="h2">
                Please enter OTP sent to your email
              </Typography>

              <form
                className="mt-5"
                onSubmit={(event) => {
                  event.preventDefault();
                  // setOpen(false);
                }}
              >
                <Stack spacing={2}>
                  <FormControl>
                    <Input
                      name="otp"
                      autoFocus
                      required
                      min={4}
                      max={4}
                      placeholder="Enter OTP"
                      disabled={!allow}
                      onChange={(e) => verifyOtp(e)}
                    />
                    <div style={{}}>
                      {valMsg && (
                        <p
                          style={{
                            color: valMsg === "*OTP verified" ? "green" : "red",
                          }}
                        >
                          {valMsg}
                        </p>
                      )}
                    </div>
                  </FormControl>

                  <Link type="text" size="small" onClick={(e) => resendOtp(e)}>
                    <FormLabel sx={{ cursor: "pointer" }}>Resend OTP</FormLabel>
                  </Link>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <Button
                      disabled={allow}
                      onClick={(event) => handleSubmit(event)}
                    >
                      Submit
                    </Button>
                  </div>
                </Stack>
              </form>
            </ModalDialog>
          </Modal>
        </>
      </section>
      {/* <p
              class="mx-2 sm:block md:hidden  text-center text-lg font-semibold text-[#0F3D79] hover:text-[#0F3D79]"
            
            >
            "The application is currently supported only in a Desktop or a Laptop."
            </p> */}
      {/* <div class=" border-t border-gray-200 mt-20 md:mt-44">
        <div class="lg:flex items-center py-3 float-right md:mr-60 ">
          <div class="flex lg:mb-0 items-center">
            <a
              class="inline-block mr-4 sm:mr-10 text-lg font-semibold text-[#0F3D79] hover:text-[#0F3D79]"
              target="_blank"
              href="/privacy"
            >
              Privacy Policy
            </a>
            <a
              class="inline-block mr-4 sm:mr-10 text-lg font-semibold text-[#0F3D79] hover:text-[#0F3D79]"
              target="_blank"
              href="/agreement"
            >
              User Agreement
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
}
