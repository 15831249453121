import React, { useState, useEffect } from "react";
import { InputAdornment, TextField, FormControl } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete } from "@mui/joy";
import {
  usersConnected,
  individualUserSelector,
  diconnectIndividual,
} from "../../api/individualUser";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import {
  category,
  Subcategory,
  Subcategory2,
  Subcategory3,
  Subcategory4,
} from "../../shared/constants";

import premiumlog from "../../images/premiumlogo.png";
function MyConnections() {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]); // Filtered data for request received
  const [debouncedText] = useDebounce(search, 1000);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [studentCategory, setCategory] = useState({
    category: "",
  });

  const { connected, user, individualtoken } = useSelector(
    individualUserSelector
  );
  const dispatch = useDispatch();
  const [hoveredProfile, setHoveredProfile] = useState(null); // Track hovered profile
  // Sample options for the Autocomplete dropdown
  useEffect(() => {
    let filteredData = connected;

    if (debouncedText) {
      // Filter based on search term (name or company)
      filteredData = filteredData.filter((item) =>
        item.name.toLowerCase().includes(debouncedText.toLowerCase())
      );
    }

    if (studentCategory.category) {
      // Further filter based on category
      filteredData = filteredData.filter(
        (item) =>
          item?.profile_completion?.category === studentCategory.category
      );
    }

    setData(filteredData);
  }, [debouncedText, studentCategory.category, connected]);

  // Function to handle category change
  const handleFormCategoryChange = (event, newValue) => {
    if (newValue) {
      setCategory({ category: newValue.value });
    } else {
      setCategory({ category: "" });
    }
  };

  const handleDisConnect = (userId, connectedUserId) => {
    dispatch(diconnectIndividual({ userId, connectedUserId }, individualtoken));
  };

  // Apply filtering based on category and search term

  return (
    <div style={{ fontFamily: "Lato" }}>
      <section className="py-0">
        <div className="container mx-auto">
          {/* Header Section with Inputs */}
          <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
            {/* Left-aligned title */}
            <h1 className="text-lg font-semibold text-[#0F3D79]">
              My Connections
            </h1>

            {/* Right-aligned filter and search inputs */}
            <div className="flex flex-col lg:flex-row items-center ml-auto w-full lg:w-auto space-y-4 lg:space-y-0 lg:space-x-4">
              {/* Autocomplete for category selection */}
              <FormControl className="w-full lg:w-60">
                <Autocomplete
                  placeholder="Select Category"
                  options={category}
                  getOptionLabel={(option) => option.label}
                  onChange={handleFormCategoryChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="border rounded-xl bg-white"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>

              {/* Search input field */}
              <div className="relative w-full lg:w-60">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon className="text-gray-500" />
                </span>
                <input
                  type="text"
                  placeholder="Search by name"
                  className="py-2 pl-10 pr-4 w-full rounded-xl border border-gray-300"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Cards Section */}
          <div className="flex flex-wrap -m-4">
            {data.map((item) => (
              <div
                key={item._id}
                className="w-full lg:w-1/4 p-2 cursor-pointer"
              >
                <div
                  className="relative p-4 bg-white shadow-md rounded-xl hover:shadow-lg transition-shadow duration-300"
                  style={{ minHeight: "250px", maxHeight: "250px" }}
                >
                  {(item?.period === 2 || item?.period === 3) && (
                    <img
                      src={premiumlog} // Replace with the actual URL or path of your top-right image
                      alt="icon"
                      className="absolute top-0 left-2 w-10 h-10" // Adjust width and height as needed
                    />
                  )}
                  <img
                    className="w-24 h-24 mx-auto object-cover rounded-full border border-gray-200"
                    src={
                      item?.profile_completion?.profile_pic
                        ? item?.profile_completion?.profile_pic
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt="Profile"
                  />
                  <h3 className="text-center font-semibold text-lg text-[#0F3D79] mt-2">
                    {item?.name ? item.name : "Name"}
                  </h3>
                  <p className="text-center text-md text-[#404040] mt-1 mb-3">
                    {item?.profile_completion?.category === "Student"
                      ? "Student"
                      : item?.profile_completion?.designation || "Designation"}
                  </p>

                  <div className="flex justify-center">
                    <button
                      className="flex items-center justify-center py-1 px-4 bg-[#0F3D79] text-white border border-blue-500 rounded-[5px] transition-colors hover:bg-blue-600"
                      style={{ width: "160px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDisConnect(user?._id, item._id);
                      }}
                    >
                      Connected
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyConnections;
