import React, { useState, useEffect } from "react";
import { InputAdornment, TextField, FormControl } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import {
  individualUserSelector,
  usersRequestReceived,
  declineConnectionRequest,
  acceptConnectionRequest,
  usersRequestSent,
  declineRequestSent,
} from "../../api/individualUser";
import { useDebounce } from "use-debounce";
import nodata from "../../images/no-data.png";
import {
  category,
  Subcategory,
  Subcategory2,
  Subcategory3,
  Subcategory4,
} from "../../shared/constants";

import premiumlog from "../../images/premiumlogo.png";

function MyRequest() {
  const [search, setSearch] = useState("");
  const [dataReceived, setDataReceived] = useState([]); // Filtered data for request received
  const [dataSent, setDataSent] = useState([]); // Filtered data for request sent
  const [debouncedText] = useDebounce(search, 1000);

  const [studentCategory, setCategory] = useState({
    category: "",
  });
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const { individualtoken, user, request_received, request_sent } = useSelector(
    individualUserSelector
  );
  const [hoveredProfile, setHoveredProfile] = useState(null); // Track hovered profile
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersRequestReceived(user?._id));
    dispatch(usersRequestSent(user?._id));
  }, [user]);

  // Common filter logic for both request_received and request_sent
  useEffect(() => {
    const applyFilters = (list) => {
      let filteredData = list;

      if (debouncedText) {
        // Filter based on search term (name or company)
        filteredData = filteredData.filter((item) =>
          item.name.toLowerCase().includes(debouncedText.toLowerCase())
        );
      }

      if (studentCategory.category) {
        // Further filter based on category
        filteredData = filteredData.filter(
          (item) =>
            item?.profile_completion?.category === studentCategory.category
        );
      }

      return filteredData;
    };

    // Apply filters to request_received and request_sent
    setDataReceived(applyFilters(request_received));
    setDataSent(applyFilters(request_sent));
  }, [debouncedText, studentCategory.category, request_received, request_sent]);

  const acptHandel = (acceptorId, recieverId) => {
    dispatch(
      acceptConnectionRequest({ acceptorId, recieverId }, individualtoken)
    );
  };

  const declineHandel = (userId, declinedId) => {
    dispatch(declineConnectionRequest({ userId, declinedId }, individualtoken));
  };

  const handledeclineRequestSent = (senderId, receiverId) => {
    dispatch(declineRequestSent({ senderId, receiverId }, individualtoken));
  };

  // Function to handle category change
  const handleFormCategoryChange = (event, newValue) => {
    if (newValue) {
      setCategory({ category: newValue.value });
    } else {
      setCategory({ category: "" });
    }
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <section className="py-0">
        <div className="container mx-auto">
          {/* Header Section with Inputs */}
          <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
            {/* Left-aligned title */}
            <h1 className="text-lg font-semibold text-[#0F3D79]">
              My Requests
            </h1>

            {/* Right-aligned filter and search inputs */}
            <div className="flex flex-col lg:flex-row items-center ml-auto w-full lg:w-auto space-y-4 lg:space-y-0 lg:space-x-4">
              {/* Autocomplete for category selection */}
              <FormControl className="w-full lg:w-60">
                <Autocomplete
                  placeholder="Select Category"
                  options={category}
                  getOptionLabel={(option) => option.label}
                  onChange={handleFormCategoryChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="border rounded-xl bg-white"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>

              {/* Search input field */}
              <div className="relative w-full lg:w-60">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon className="text-gray-500" />
                </span>
                <input
                  type="text"
                  placeholder="Search by name"
                  className="py-2 pl-10 pr-4 w-full rounded-xl border border-gray-300"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Request Received Section */}
          <h2 className="text-md font-semibold text-[#0F3D79] ml-4 mb-5">
            Request Received
          </h2>
          {dataReceived?.length > 0 ? (
            <div className="flex flex-wrap -m-4">
              {dataReceived.map(
                (item, i) =>
                  item?.profile_activate_status && (
                    <div key={i} className="w-full md:w-1/3 lg:w-1/4 p-4">
                      <div className="relative p-4 bg-white shadow-md rounded-xl hover:shadow-lg transition-shadow duration-300">
                        {(item?.period === 2 || item?.period === 3) && (
                          <img
                            src={premiumlog} // Replace with the actual URL or path of your top-right image
                            alt="icon"
                            className="absolute top-0 left-2 w-10 h-10" // Adjust width and height as needed
                          />
                        )}
                        <img
                          className="w-24 h-24 mx-auto object-cover rounded-full border border-gray-200"
                          src={
                            item?.profile_completion?.profile_pic ||
                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                          alt="Profile"
                        />
                        <h3 className="text-center font-semibold text-lg text-[#0F3D79] mt-2">
                          {item?.name || "Name"}
                        </h3>
                        <p className="text-center text-md text-[#404040] mt-1 mb-3">
                          {item?.profile_completion?.category || "Designation"}
                        </p>
                        <div className="flex justify-between">
                          <button
                            className="px-4 py-1 bg-white text-[#0F3D79] border border-[#0F3D79] rounded-lg hover:bg-gray-100"
                            onClick={() => declineHandel(user?._id, item._id)}
                          >
                            Reject
                          </button>
                          <button
                            className="px-4 py-1 bg-[#0F3D79] text-white rounded-lg hover:bg-blue-600"
                            onClick={() => acptHandel(user?._id, item._id)}
                          >
                            Accept
                          </button>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              {/* <img src={nodata} alt="No Data" height={200} width={200} /> */}
              <p className="mt-2">No Data Available</p>
            </div>
          )}

          {/* Request Sent Section */}
          <h2 className="text-md font-semibold text-[#0F3D79] ml-4 mt-6 mb-5">
            Request Sent
          </h2>
          {dataSent?.length > 0 ? (
            <div className="flex flex-wrap -m-4">
              {dataSent?.map(
                (item, i) =>
                  item?.profile_activate_status && (
                    <div key={i} className="w-full md:w-1/3 lg:w-1/4 p-4">
                      <div className=" relative p-4 bg-white shadow-md rounded-xl hover:shadow-lg transition-shadow duration-300">
                        {(item?.period === 2 || item?.period === 3) && (
                          <img
                            src={premiumlog} // Replace with the actual URL or path of your top-right image
                            alt="icon"
                            className="absolute top-0 left-2 w-10 h-10" // Adjust width and height as needed
                          />
                        )}
                        <img
                          className="w-24 h-24 mx-auto object-cover rounded-full border border-gray-200"
                          src={
                            item?.profile_completion?.profile_pic ||
                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                          alt="Profile"
                        />
                        <h3 className="text-center font-semibold text-lg text-[#0F3D79] mt-2">
                          {item?.name || "Name"}
                        </h3>
                        <p className="text-center text-md text-[#404040] mt-1 mb-3">
                          {item?.profile_completion?.category || "Designation"}
                        </p>
                        <div className="flex justify-center">
                          <button
                            className="px-4 py-1 bg-white font-semibold text-[#0F3D79] border border-[#0F3D79] rounded-lg hover:bg-gray-100"
                            onClick={() =>
                              handledeclineRequestSent(user?._id, item._id)
                            }
                          >
                            Withdraw
                          </button>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              {/* <img src={nodata} alt="No Data" height={200} width={200} /> */}
              <p className="mt-2">No Data Available</p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default MyRequest;
