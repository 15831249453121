import React from "react";
import { useNavigate } from "react-router";
import premiumlog from "../../images/premiumlogo.png";

function ProfileLogoInd({ user }) {
  const nav = useNavigate();

  return (
    <div>
      <div className="mb-6">
        <div className="pb-10 pt-3 mt-2  relative bg-gray-100 shadow rounded-lg overflow-visible h-80">
          {/* Top-right small image */}
          {(user?.period === 2 || user?.period === 3) && (
            <img
              src={premiumlog} // Replace with the actual URL or path of your top-right image
              alt="icon"
              className="absolute top-0 left-2 w-10 h-10" // Adjust width and height as needed
            />
          )}
          {/* <img
            src={premiumlog} // Replace with the actual URL or path of your top-right image
            alt="icon"
            className="absolute top-0 right-2 w-10 h-10" // Adjust width and height as needed
          /> */}

          {/* Profile image */}
          <img
            onClick={() => nav("/home")}
            className="relative w-32 h-32 mb-4 mt-8 mx-auto rounded-full object-cover object-right cursor-pointer"
            src={
              user?.profile_completion?.profile_pic
                ? user?.profile_completion?.profile_pic
                : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
            }
            alt="Profile"
          />
          <div className="px-6  text-center">
            <p className="text-lg font-semibold text-[#0F3D79] mb-1">
              {user?.name}
            </p>
            <p className="text-md font-semibold text-gray-500 mb-1">
              {user?.profile_completion?.category === ""
                ? ""
                : user?.profile_completion?.category === "Student"
                ? "Student"
                : user?.profile_completion?.designation
                    ?.charAt(0)
                    .toUpperCase() + user?.profile_completion?.designation?.slice(1)}
            </p>
            <p className="text-sm font-semibold text-gray-500">{user?.unique_id}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileLogoInd;
